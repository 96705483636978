import { ReactNode, useCallback, useMemo } from 'react';
import { DualAxes } from '@ant-design/charts';
import { Card } from 'antd';
import { toShortCount, toShortPrice } from 'util/number';

import EmptyMessage from 'components/States/EmptyMessage/EmptyMessage';
import ErrorMessage from 'components/States/ErrorMessage/ErrorMessage';
import LoadingCard from 'components/States/LoadingCard/LoadingCard';
import CardTitle from 'components/Typography/CardTitle/CardTitle';

interface ColumnLineChartProps {
  loading: boolean;
  title?: ReactNode;
  help?: string;
  error?: string;
  xField: string;
  charts: Array<{
    data?: Array<any>;
    type: string;
    yField: string;
    colorField: string;
    showY: boolean;
    yFormat: string;
    yLabel?: string;
    titleColor: string;
    position: string;
    style?: any
  }>;
  colors: string[];
  data: Array<Record<string, any>>;
}

const ColumnLineChart = ({
                           title,
                           help,
                           data,
                           xField,
                           loading,
                           error,
                           charts,
                           colors
                         }: ColumnLineChartProps) => {
  const toDateColumn = useCallback((data: { [key: string]: number }) => {
    return new Date(data[xField] * 1000).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      timeZone: 'UTC'
    });
  }, [xField]);

  const config = useMemo(() => {
    return {
      xField: toDateColumn,
      scale: { color: { range: colors } },
      children: charts.map(chart => ({
        data: chart.data || data,
        type: chart.type || 'interval',
        yField: chart.yField,
        colorField: chart.colorField,
        group: true,
        axis: {
          y: chart.showY
            ? {
              labelFormatter: chart.yFormat === 'USD' ? toShortPrice : toShortCount,
              grid: true,
              gridLineDash: null,
              title: chart.yLabel,
              style: { titleFill: chart.titleColor },
              position: chart.position || 'left'
            }
            : false,
          x: { nice: true, labelAutoRotate: false, tickInterval: 4 }
        },
        tooltip: { items: [{ channel: 'y', valueFormatter: (v: number) => v.toLocaleString() }] },
        style: chart.style
      }))
    };
  }, [data, toDateColumn, charts, colors]);

  if (loading) {
    return <LoadingCard structure={{ type: 'NODE', width: '100%', height: 354 }}/>;
  }

  return (
    <Card
      bordered={false}
      loading={loading}
      title={title && typeof title === 'string' ? <CardTitle title={title} help={help}/> : title}
    >
      <div style={{ height: 262 }}>
        {!data.length && !error && <EmptyMessage/>}
        {!data.length && error && <ErrorMessage/>}
        <DualAxes {...config}/>
      </div>
    </Card>
  );
};

export default ColumnLineChart;
