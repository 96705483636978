import { useMemo } from 'react';
import { Card, Flex, Typography } from 'antd';

import MicroLineChart from '../../../../../../components/Charts/MicroLineChart/MicroLineChart';
import CardTitle from '../../../../../../components/Typography/CardTitle/CardTitle';

import styles from './styles.module.css';

interface CommingSoonCardProps {
  title: string;
  help?: string;
  loading: boolean;
}

const CommingSoonCard = ({ title, help, loading }: CommingSoonCardProps) => {
  const data = useMemo(() => {
    return [
      100, 100, 100, 100, 100, 99, 100, 101, 100, 100, 100, 100
    ].map((value, index) => ({ value, index }));
  }, []);

  return (
    <Card
      className={styles.statsCard}
      loading={loading}
      bordered={false}
      title={<CardTitle title={title} help={help}/>}
    >
      <Flex justify={'end'}>
        <MicroLineChart data={data} xField={'index'} yField={'value'} color={'rgba(115, 118, 128, 0.3)'}/>
      </Flex>
      <Flex>
        <Typography.Text strong style={{ fontSize: 18 }}>Coming soon...</Typography.Text>
      </Flex>
      <Flex justify="space-between">
        <Typography.Text className={styles.period}>Since last day</Typography.Text>
      </Flex>
    </Card>
  );
};

export default CommingSoonCard;
