import { SessionUser } from 'model/User';
import { sessionClient } from 'service/SessionService';
import { create } from 'zustand';

interface SessionState {
  loadingSession: boolean;
  user?: SessionUser;
  error: string;
}

interface SessionActions {
  getSession: () => void;
}

const initialState: SessionState = {
  loadingSession: false,
  error: '',
  user: undefined
};

export const useSessionStore = create<SessionState & SessionActions>((set, get) => ({
  ...initialState,

  getSession: async () => {
    try {
      set({ loadingSession: true, error: undefined });
      const session = await sessionClient.getSession();
      set({
        loadingSession: false,
        user: session
      });
    } catch (error) {
      set({ error: error.message, loadingSession: false });
    }
  }
}));
