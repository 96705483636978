import React from 'react';
import { TokenHistory } from 'model/Wallet';

import ColumnLineChart from 'components/Charts/ColumnLineChart/ColumnLineChart';
import EmptyMessage from 'components/States/EmptyMessage/EmptyMessage';
import ErrorMessage from 'components/States/ErrorMessage/ErrorMessage';

interface HistoryChartProps {
  history: TokenHistory;
  loadingHistory: boolean;
  error?: string;
}

const HistoryChart = ({ history, error, loadingHistory }: HistoryChartProps) => {
  if (error) {
    return <div style={{ height: 120 }}>
      <ErrorMessage/>
    </div>;
  }

  if (!history.data.length) {
    return <div style={{ height: 120 }}>
      <EmptyMessage/>
    </div>;
  }

  return (
    <ColumnLineChart loading={loadingHistory}
                     error={error}
                     data={history.data}
                     colors={['#5AD8A6', '#F4A49E', '#B45AFA']}
                     xField={'timestamp'}
                     charts={[
                       {
                         position: 'left',
                         type: 'interval',
                         yField: 'value',
                         data: history.priceHistory,
                         titleColor: '#5AD8A6',
                         colorField: 'type',
                         showY: false,
                         yLabel: 'Price USD',
                         style: { width: 5},
                         yFormat: 'USD'
                       },
                       {
                         position: 'right',
                         type: 'interval',
                         yField: 'value',
                         titleColor: '#F4A49E',
                         style: { width: 5, insetLeft: 15},
                         data: history.amountHistory,
                         colorField: 'type',
                         yLabel: 'Balance',
                         showY: true,
                         yFormat: 'NUMBER'
                       },
                       {
                         position: 'left',
                         type: 'line',
                         yField: 'value',
                         titleColor: '#B45AFA',
                         yLabel: 'Balance USD',
                         style: { lineWidth: 2 },
                         showY: true,
                         data: history.balanceHistory,
                         colorField: 'type',
                         yFormat: 'USD'
                       }
                     ]}/>
  );
};

export default HistoryChart;
