import instance from 'config/AxiosConfig';
import { DictionaryRequest,SupportedProtocols, SupportedTokens } from 'model/Dictionary';

export class DictionaryClient {
  private static _instance = new DictionaryClient();

  static get instance(): DictionaryClient {
    return this._instance;
  }

  readonly host: string;

  constructor() {
    this.host = window.config.API_URL;
  }

  public async getSupportedTokens({ isSupportHistory }: DictionaryRequest): Promise<SupportedTokens> {
    const url = `${this.host}/api/v1/tokens`;
    const result = await instance.get<SupportedTokens>(url, { params: { is_support_history: isSupportHistory } });
    return result.data;
  }

  public async getSupportedProtocols({ isSupportHistory }: DictionaryRequest): Promise<SupportedProtocols> {
    const url = `${this.host}/api/v1/protocols`;
    const result = await instance.get<SupportedProtocols>(url, { params: { is_support_history: isSupportHistory } });
    return result.data;
  }
}

export const dictionaryClient = DictionaryClient.instance;
