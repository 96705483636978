import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from 'antd';

interface UsdValueProps {
  value: number;
  className?: string;
  size?: number;
  strong?: boolean;
}

const UsdValue = ({ className, value, size = 14, strong = false }: UsdValueProps) => {
  const { formatNumber } = useIntl();

  const adjustedValue = useMemo(() => {
    if (value > 0 && value < 0.01) {
      return `<${formatNumber(0.01, { style: 'currency', currency: 'USD' })}`
    } else {
      return formatNumber(value, { style: 'currency', currency: 'USD' })
    }
  }, [value, formatNumber]);

  return <Typography.Text strong={strong} className={className} style={{ fontSize: size }}>
    {adjustedValue}
  </Typography.Text>;
};

export default UsdValue;
