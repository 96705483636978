import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import Search from 'components/Inputs/Search/Search';

import styles from './styles.module.css'

interface SearchAddressProps {
  address: string;
  setAddress: (address: string) => void;
}

const SearchAddress = ({ address, setAddress}: SearchAddressProps) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMacPressed = event.metaKey && event.key === "k";
      const isOtherPressed = event.ctrlKey && event.key === "k";
      if (isMacPressed || isOtherPressed) {
        inputRef.current?.focus();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Search
      customClass={styles.search}
      inputRef={inputRef}
      defaultValue={address}
      hotKey={address ? undefined : { component: <span className={styles.suffix}>&#8984;K</span> }}
      placeholder={formatMessage({ id: 'HEADER_SEARCH' })}
      onSearch={setAddress}
    />
  );
};

export default SearchAddress;
