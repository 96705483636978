import { useMemo } from 'react';
import { Avatar, Flex, Typography } from 'antd';

import ChainIcon from 'components/Icons/ChainIcon/ChainIcon';
import UsdValue from 'components/Typography/UsdValue/UsdValue';

import ItemsRatioBar from '../ItemsRatioBar/ItemsRatioBar';

import styles from './styles.module.css';

interface ChainCardProps {
  chainId: string;
  chainName: string;
  value: number;
  totalValue: number;
}

const ChainCard = ({ chainName, chainId, value, totalValue }: ChainCardProps) => {
  const percentage = useMemo(() => {
    return Math.round(value / totalValue * 100);
  }, [totalValue, value]);

  return <Flex className={styles.chainCard} align={'center'} gap={'small'}>
    <div style={{ width: 32 }}>
      <Avatar size={32} style={{ backgroundColor: '#ECEFF0' }} icon={<ChainIcon chain={chainId} chainName={chainName} size={32}/>}/>
    </div>
    <Flex vertical className={styles.chainInfo}>
      <Typography.Text className={styles.chainName}>{chainName}</Typography.Text>
      <Flex justify={'space-between'} align={'center'}>
        <UsdValue value={value} size={14} strong></UsdValue>
        <Typography.Text className={styles.chainPercentage}>{percentage}%</Typography.Text>
      </Flex>
    </Flex>
    <Flex>
      <ItemsRatioBar totalValue={totalValue} values={[value]} colors={['#57C7FF']}/>
    </Flex>
  </Flex>;
};

export default ChainCard;
