import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ChartInterval } from 'model/Chart';
import { useRootStore } from 'store/portfolioStore';

import LineChart from 'components/Charts/LineChart/LineChart';

import { useDashboardStore } from '../../store';

const DefiProfitChart = () => {
  const { formatMessage } = useIntl();
  const [interval, setInterval] = useState<string>(ChartInterval.ONE_DAY);
  const refreshTime = useRootStore(state => state.refreshTime);
  const address = useRootStore(state => state.address);
  const loadingDefiProfit = useDashboardStore(state => state.loadingDefiProfit);
  const defiProfit = useDashboardStore(state => state.defiProfit);
  const loadDefiProfit = useDashboardStore(state => state.loadDefiProfit);

  useEffect(() => {
    loadDefiProfit(address, interval);
  }, [address, loadDefiProfit, interval, refreshTime]);

  return (
    <LineChart loading={loadingDefiProfit}
               title={`${formatMessage({ id: 'DASHBOARD_DEFI_PROFIT' })}. Comming soon...`}
               data={defiProfit.data}
               onIntervalChange={setInterval}
               color={'gray'}
               xField={'timestamp'}
               yField={'usd_value'}/>
  );
};

export default DefiProfitChart;
