import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Flex } from 'antd';
import { ChainTokenItem, SupportedChainItem } from 'model/Dictionary';
import { ReactComponent as DownloadIcon } from 'resource/icons/download.svg';
import { useDictionaryStore } from 'store/dictionaryStore';
import { useRootStore } from 'store/portfolioStore';

import ButtonOutlined from 'components/Buttons/ButtonOutlined/ButtonOutlined';
import ChainFilter from 'components/Filters/ChainFilter/ChainFilter';
import MultiTokenFilter from 'components/Filters/MultiTokenFilter/MultiTokenFilter';

interface CardHeaderProps {
  mode?: 'table' | 'chart';
  toggleMode?: () => void;
  loadData: (address: string, tokens: Array<string>, chain: string) => void;
}

const CardHeader = ({ toggleMode, mode, loadData }: CardHeaderProps) => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const [selectedTokens, setSelectedTokens] = useState<ChainTokenItem[]>([]);
  const [selectedChain, setSelectedChain] = useState<SupportedChainItem>();
  const chainsWithHistory = useDictionaryStore(state => state.chainsWithHistory);
  const address = useRootStore(state => state.address);
  const refreshTime = useRootStore(state => state.refreshTime);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const history = queryParams.getAll('history');
    const chainId = queryParams.get('chain');
    if (history?.length && chainId) {
      const chain = chainsWithHistory.chains.find(chain => chain.id === chainId);
      setSelectedChain(chain);
      if (chain) {
        const tokens = chain?.tokens.filter(token => token.address && history.includes(token.address));
        setSelectedTokens(tokens);
      }
    }
  }, [chainsWithHistory, location.search]);

  useEffect(() => {
    const tokens = selectedTokens.map(t => t.symbol);
    address && tokens.length && selectedChain && loadData(address, tokens, selectedChain.id);
  }, [loadData, address, selectedChain, selectedTokens, refreshTime]);

  const onChainChange = useCallback((item?: SupportedChainItem) => {
    setSelectedChain(item);
    setSelectedTokens([]);
  }, [setSelectedChain, setSelectedTokens]);

  return (
    <Flex justify={'space-between'} align={'center'}>
      <Flex gap={'middle'} wrap>
        <ChainFilter chains={chainsWithHistory.chains}
                     selectedChain={selectedChain}
                     onChange={onChainChange}/>
        <MultiTokenFilter tokens={selectedChain?.tokens}
                          selectedTokens={selectedTokens}
                          onChange={setSelectedTokens}
                          disabled={!selectedChain}/>
      </Flex>
      <Flex gap={'small'}>
        {mode && <ButtonOutlined text={mode === 'table' ? 'Chart' : 'Table'} onClick={toggleMode}/>}
        <ButtonOutlined
          text={formatMessage({ id: 'COMMON_DOWNLOAD_CSV' })}
          icon={<DownloadIcon/>}
        />
      </Flex>
    </Flex>
  );
};

export default CardHeader;
