import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card } from 'antd';
import { DefiItem } from 'model/Portfolio';
import { useRootStore } from 'store/portfolioStore';

import ScrollableTable from 'components/DataDisplay/ScrollableTable/ScrollableTable';
import ChainNameWithIcon from 'components/Icons/ChainNameWithIcon/ChainNameWithIcon';
import ProtocolNameWithIcon from 'components/Icons/ProtocolNameWithIcon/ProtocolNameWithIcon';
import Search from 'components/Inputs/Search/Search';
import LoadingCard from 'components/States/LoadingCard/LoadingCard';
import UsdValue from 'components/Typography/UsdValue/UsdValue';

const DeFi = () => {
  const { formatMessage } = useIntl();
  const error = useRootStore(state => state.error);
  const loadingPortfolio = useRootStore(state => state.loadingPortfolio);
  const defis = useRootStore(state => state.defis);
  const [filteredDefis, setFilteredDefis] = useState<Array<DefiItem>>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const newDefis = defis.filter(defi => defi.protocol_name?.toLowerCase()?.includes(search?.toLowerCase()));
    setFilteredDefis(newDefis);
  }, [defis, search]);

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
  }, [setSearch]);

  const columns = useMemo(() => ([
    {
      title: formatMessage({ id: 'DASHBOARD_DEFI_PROTOCOL_COLUMN' }),
      dataIndex: 'protocol_name',
      width: 350,
      render: (protocolName: string) => <ProtocolNameWithIcon protocol={protocolName} iconSize={16} fontSize={12}/>,
      align: 'left' as const
    },
    {
      title: formatMessage({ id: 'DASHBOARD_DEFI_CHAIN_COLUMN' }),
      render: (item: DefiItem) => <ChainNameWithIcon chain={item.protocol_chain} chainName={item.protocol_chain_name}
                                                     iconSize={16} fontSize={12}/>,
      width: 350,
      align: 'left' as const
    },
    {
      title: formatMessage({ id: 'DASHBOARD_DEFI_VALUE_COLUMN' }),
      dataIndex: 'value_usd',
      render: (value: number) => <UsdValue value={value} size={12}/>,
      width: 350,
      align: 'left' as const
    }
  ]), [formatMessage]);

  if (loadingPortfolio) {
    return <LoadingCard structure={{ type: 'NODE', width: '100%', height: 239 }}/>;
  }

  return <Card
    bordered={false}
    loading={loadingPortfolio}
    title={formatMessage({ id: 'DASHBOARD_DEFI' })}
    extra={<Search loading={false}
                   onSearch={handleSearch}
                   placeholder={formatMessage({ id: 'DASHBOARD_DEFI_SEARCH' })}/>}
  >
    <ScrollableTable data={filteredDefis}
                     columns={columns}
                     scrollSize={300}
                     pagination={false}
                     loading={false}
                     error={error}
    />
  </Card>;
};

export default DeFi;
