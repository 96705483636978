import { Flex, Typography } from 'antd';

import ProtocolIcon from '../ProtocolIcon/ProtocolIcon';

interface ProtocolNameWithIconProps {
  protocol: string;
  protocolName?: string;
  iconSize?: number;
  fontSize?: number;
}

const ProtocolNameWithIcon = ({ protocol, protocolName, iconSize = 24, fontSize = 12 }: ProtocolNameWithIconProps) => {
  return <Flex gap={'small'} align={'center'}>
    <ProtocolIcon protocol={protocol} size={iconSize}/>
    <Typography.Text style={{ fontSize }}>{protocolName ?? protocol}</Typography.Text>
  </Flex>;
};

export default ProtocolNameWithIcon;
