import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SupportedChainItem } from 'model/Dictionary';
import { LabelInValueType } from 'rc-select/lib/Select';

import ChainNameWithIcon from 'components/Icons/ChainNameWithIcon/ChainNameWithIcon';
import Select from 'components/Inputs/Select/Select';

interface ChainFilterProp {
  disabled?: boolean;
  chains?: Array<SupportedChainItem>;
  selectedChain?: SupportedChainItem;
  onChange: (item?: SupportedChainItem) => void;
}

const ChainFilter = ({ onChange, selectedChain, chains = [], disabled = false }: ChainFilterProp) => {
  const { formatMessage } = useIntl();

  const options = useMemo(() => {
    return chains
      .map(chain => ({
        ...chain,
        label: chain.name,
        value: chain.id
      }));
  }, [chains]);

  const optionRender = useCallback((option: { data: SupportedChainItem }) => {
    return <ChainNameWithIcon chain={option.data.id} chainName={option.data.name} iconSize={16}/>;
  }, []);

  const labelRender = useCallback((option: LabelInValueType) => {
    return <ChainNameWithIcon chain={option.value as string}
                              chainName={option.label as string}
                              iconSize={16}/>;
  }, []);

  const onChainChange = useCallback((_key: string, item?: SupportedChainItem | SupportedChainItem[]) => {
    if (item) {
      Array.isArray(item) ? onChange(item[0]) : onChange(item);
    } else {
      onChange(undefined);
    }
  }, [onChange]);

  return <Select placeholder={formatMessage({ id: 'COMMON_CHAIN_FILTER' })}
                 value={selectedChain?.id}
                 options={options}
                 optionRender={optionRender}
                 labelRender={labelRender}
                 disabled={disabled}
                 showSearch={true}
                 width={220}
                 onChange={onChainChange}/>;
};

export default ChainFilter;
