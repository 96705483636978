import React, { ReactNode } from 'react';
import { Select as AntdSelect } from 'antd';
import type { FlattenOptionData } from 'rc-select/lib/interface';
import { LabelInValueType } from 'rc-select/lib/Select';
import { ReactComponent as ArrowDownIcon } from 'resource/icons/arrow/arrow-down.svg';
import { ReactComponent as SearchIcon } from 'resource/icons/search.svg';

import styles from './styles.module.css';


export interface BaseOption {
  value: string;
  label: string;
}

interface SelectProps<T extends BaseOption> {
  mode?: 'multiple';
  options: Array<T>;
  width?: number | string;
  optionRender?: (option: FlattenOptionData<T>) => ReactNode;
  labelRender?: (option: LabelInValueType) => ReactNode;
  defaultValue?: any;
  value?: any,
  placeholder?: string;
  showSearch?: boolean;
  disabled?: boolean;
  onChange: (key: any, item?: T | T[]) => void;
}

const Select = <T extends BaseOption, >({
                                          mode,
                                          options,
                                          optionRender,
                                          labelRender,
                                          value,
                                          defaultValue,
                                          placeholder,
                                          onChange,
                                          showSearch,
                                          disabled,
                                          width = 100
                                        }: SelectProps<T>) => {
  return (
    <AntdSelect
      mode={mode}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      prefix={showSearch ? <SearchIcon style={{ color: 'rgba(0,0,0,.25)', marginRight: 5 }}/> : undefined}
      className={styles.select}
      suffixIcon={<ArrowDownIcon width={10} height={10} className={styles.suffixIcon}/>}
      defaultValue={defaultValue}
      style={{ minWidth: width }}
      showSearch={showSearch}
      options={options}
      onChange={onChange}
      optionRender={optionRender}
      labelRender={labelRender}
    />
  );
};

export default Select;
