import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Menu, MenuProps } from 'antd';
import { ReactComponent as MessageIcon } from 'resource/icons/message-text-square.svg';
import { ReactComponent as RefreshIcon } from 'resource/icons/refresh.svg';

import styles from 'layouts/LambdaLayout/components/SettingsMenu/styles.module.css';

interface SettingsMenuProps {
  refresh: () => void
}

const SettingsMenu = ({ refresh }: SettingsMenuProps) => {
  const { formatMessage } = useIntl();
  const [selectedKey, setSelectedKey] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    setSelectedKey(pathname);
  }, [pathname]);

  const handleNavigate: MenuProps['onClick'] = useCallback((item: { key: string }) => {
    if (item.key === 'help_center') {
      window.open('https://p2p-lambda.readme.io/docs/get-api-key', '_blank', 'noopener,noreferrer');
      return;
    }
    if (item.key === 'refresh') {
      refresh();
      return;
    }
  }, [refresh]);

  return (
    <Menu
      className={styles.navBar}
      mode="inline"
      selectedKeys={[selectedKey]}
      onClick={handleNavigate}
      items={[
        {
          key: 'refresh',
          icon: <RefreshIcon/>,
          label: formatMessage({ id: 'NAV_BAR_REFRESH' })
        },
        {
          key: 'help_center',
          icon: <MessageIcon/>,
          label: formatMessage({ id: 'NAV_BAR_HELP_CENTER' })
        }
      ]}
    />
  );
};

export default SettingsMenu;
