import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card } from 'antd';
import { TokenChainItem, TokenChains } from 'model/Portfolio';
import { useRootStore } from 'store/portfolioStore';

import ScrollableTable from 'components/DataDisplay/ScrollableTable/ScrollableTable';
import TokenWithIcon from 'components/Icons/TokenWithIcon/TokenWithIcon';
import Search from 'components/Inputs/Search/Search';
import LoadingCard from 'components/States/LoadingCard/LoadingCard';
import UsdValue from 'components/Typography/UsdValue/UsdValue';

import TokenChainAmount from './components/TokenChainAmount';
import TokenChainValue from './components/TokenChainValue';

const WalletTokens = () => {
  const { formatMessage } = useIntl();
  const error = useRootStore(state => state.error);
  const loadingPortfolio = useRootStore(state => state.loadingPortfolio);
  const tokenChains = useRootStore(state => state.tokenChains);
  const [filteredTokens, setFilteredTokens] = useState<Array<TokenChains>>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const tokens = tokenChains.filter(token => token.name.toLowerCase().includes(search?.toLowerCase()));
    setFilteredTokens(tokens);
  }, [tokenChains, search]);

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
  }, [setSearch]);

  const columns = useMemo(() => ([
    {
      title: formatMessage({ id: 'BALANCE_TABLE_TOKEN_COLUMN' }),
      dataIndex: 'name',
      width: 250,
      render: (token: string) => <TokenWithIcon token={token} iconSize={16} fontSize={12}/>,
      align: 'left' as const
    },
    {
      key: 'name',
      title: formatMessage({ id: 'BALANCE_TABLE_PRICE_COLUMN' }),
      dataIndex: 'price',
      render: (value: number) => <UsdValue value={value} size={12}/>,
      width: 100,
      align: 'right' as const
    },
    {
      key: 'name',
      title: formatMessage({ id: 'BALANCE_TABLE_AMOUNT_COLUMN' }),
      dataIndex: 'chains',
      render: (chains: Array<TokenChainItem>) => <TokenChainAmount chains={chains} size={12}/>,
      width: 250,
      align: 'right' as const
    },
    {
      key: 'name',
      title: formatMessage({ id: 'BALANCE_TABLE_VALUE_COLUMN' }),
      width: 100,
      render: (token: TokenChains) => <TokenChainValue token={token.name} chains={token.chains} size={12}/>,
      align: 'right' as const
    }
  ]), [formatMessage]);

  if (loadingPortfolio) {
    return <LoadingCard structure={{ type: 'NODE', width: '100%', height: 291 }}/>;
  }

  return <Card
    bordered={false}
    loading={loadingPortfolio}
    title={formatMessage({ id: 'DASHBOARD_WALLER_TOKENS' })}
    extra={<Search loading={false}
                   onSearch={handleSearch}
                   placeholder={formatMessage({ id: 'DASHBOARD_WALLET_TOKENS_SEARCH' })}/>}
  >
    <ScrollableTable data={filteredTokens}
                     columns={columns}
                     scrollSize={300}
                     pagination={false}
                     error={error}
                     loading={false}/>
  </Card>;
};

export default WalletTokens;
