import { useMemo } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';
import chronosExchange from 'resource/icons/crypto/CHRONOS_EXCHANGE.png';
import compound from 'resource/icons/crypto/COMPOUND.png';
import crv from 'resource/icons/crypto/CRV.png';
import { ReactComponent as EtherfiIcon } from 'resource/icons/crypto/etherfi.svg';
import koi from 'resource/icons/crypto/KOI.png';
import syncSwap from 'resource/icons/crypto/SYNC_SWAP.png';
import zkNation from 'resource/icons/crypto/ZK_NATION.png';


interface ProtocolIconProps {
  protocol: string;
  size?: number;
}

const ProtocolIcon = ({ protocol, size = 30 }: ProtocolIconProps) => {
  const image = useMemo(() => {
    switch (protocol) {
      case 'ether.fi':
        return { icon: <EtherfiIcon/> };
      case 'Curve':
        return { src: crv };
      case 'ZK Nation':
        return { src: zkNation };
      case 'Compound V3':
        return { src: compound };
      case 'Chronos Exchange':
        return { src: chronosExchange };
      case 'Koi':
        return { src: koi };
      case 'SyncSwap':
        return { src: syncSwap };
      default:
        return { icon: <QuestionCircleOutlined/> };
    }
  }, [protocol]);


  return <Tooltip title={protocol}>
    <Avatar size={size} {...image}/>
  </Tooltip>;

};

export default ProtocolIcon;
