import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRootStore } from 'store/portfolioStore';

import LineChart from 'components/Charts/LineChart/LineChart';

import { ChartInterval } from '../../../../model/Chart';
import { useDashboardStore } from '../../store';

const NetWorthChart = () => {
  const { formatMessage } = useIntl();
  const refreshTime = useRootStore(state => state.refreshTime);
  const address = useRootStore(state => state.address);
  const [interval, setInterval] = useState<string>(ChartInterval.ONE_DAY);
  const loadingNetWorth = useDashboardStore(state => state.loadingNetWorth);
  const error = useDashboardStore(state => state.error);
  const netWorth = useDashboardStore(state => state.netWorth);
  const loadNetworth = useDashboardStore(state => state.loadNetworth);

  useEffect(() => {
    loadNetworth(address, interval);
  }, [address, loadNetworth, interval, refreshTime]);

  return (
    <LineChart loading={loadingNetWorth}
               error={error}
               title={formatMessage({ id: 'DASHBOARD_NET_WORTH' })}
               help={formatMessage({ id: 'DASHBOARD_NET_WORTH' })}
               onIntervalChange={setInterval}
               data={netWorth.data}
               color={'#B45AFA'}
               xField={'timestamp'}
               yField={'usd_value'}/>
  );
};

export default NetWorthChart;
