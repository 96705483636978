import { TokenHistory, TokenHistoryItem } from 'model/Wallet';
import { walletClient } from 'service/WalletService';
import { create } from 'zustand';

interface WalletHistoryState {
  historyError: string;
  loadingHistory: boolean;
  history: TokenHistory;
}

interface WalletHistoryActions {
  loadTokenHistory: (address: string, token: string, chain: string) => void;
  reset: () => void;
}

const initialState: WalletHistoryState = {
  historyError: '',
  loadingHistory: false,
  history: { data: [], amountHistory: [], balanceHistory: [], priceHistory: [] },
};

export const useWalletStore = create<WalletHistoryState & WalletHistoryActions>((set, get) => ({
  ...initialState,

  loadTokenHistory: async (address: string, token: string, chain: string) => {
    if (!address) {
      get().reset();
      return;
    }

    try {
      set({ loadingHistory: true });
      const history = await walletClient.getTokenHistory({ address, token, chain });
      const sortedHistory = history.data.sort((a, b) => b.timestamp - a.timestamp);
      const { balance, amount, price } = sortedHistory.reduce((acc: any, point: TokenHistoryItem) => {
          acc.balance.push({timestamp: point.timestamp, value: point.value_usd, type: 'Balance USD'});
          acc.amount.push({timestamp: point.timestamp, value: point.amount, type: 'Balance'});
          acc.price.push({timestamp: point.timestamp, value: point.price, type: 'Price USD'});
          return acc;
        }, { balance: [], amount: [], price: []});
      set({
        loadingHistory: false,
        historyError: '',
        history: {
          data: sortedHistory,
          balanceHistory: balance,
          amountHistory: amount,
          priceHistory: price
        }
      });
    } catch (error) {
      set({
        historyError: error.message,
        history: initialState.history,
        loadingHistory: false
      });
    }
  },

  reset: () => {
    set(initialState);
  }
}));
