export const toShortPrice = (value: number) => {
  return toShortNumber(value, '$')
};

export const toShortCount = (value: number) => {
  return toShortNumber(value, '')
};

const toShortNumber = (value: number, prefix: string) => {
  if (!value || Math.abs(value) < 1000) {
    return `${prefix} ${value}`;
  }

  let adjusted = value;
  let postfix = '';
  while (Math.abs(adjusted) > 999) {
    adjusted = Math.round(adjusted / 1000);
    postfix += 'K';
  }

  return `${prefix} ${adjusted + postfix}`;
};
