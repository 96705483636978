import instance from 'config/AxiosConfig';

import { Chart } from '../model/Chart';

export class NetWorthClient {
  private static _instance = new NetWorthClient();

  static get instance(): NetWorthClient {
    return this._instance;
  }

  readonly host: string;

  constructor() {
    this.host = window.config.API_URL;
  }

  public async getNetWorth(address: string, interval: string): Promise<Chart> {
    const url = `${this.host}/api/v1/addresses/${address}/net-worth?interval=${interval}`;
    const result = await instance.get<Chart>(url);
    return result.data;
  }
}

export const netWorthClient = NetWorthClient.instance;
