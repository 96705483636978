import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from 'antd';

import { useWalletStore } from '../../store';
import CardHeader from '../CardHeader/CardHeader';

import HistoryChart from './components/HistoryChart/HistoryChart';
import HistoryTable from './components/HistoryTable/HistoryTable';

const WalletHistory = () => {
  const [mode, setMode] = useState<'table' | 'chart'>('table');

  const loadTokenHistory = useWalletStore(state => state.loadTokenHistory);
  const historyError = useWalletStore(state => state.historyError);
  const loadingHistory = useWalletStore(state => state.loadingHistory);
  const history = useWalletStore(state => state.history);
  const reset = useWalletStore(state => state.reset);

  useEffect(() => {
    return reset;
  }, [reset]);

  const loadHistory = useCallback((address: string, token: string, chain: string) => {
    loadTokenHistory(address, token, chain);
  }, [loadTokenHistory]);

  const toggleMode = useCallback(() => {
    setMode(prev => prev === 'table' ? 'chart' : 'table');
  }, [setMode]);

  const reversedHistory = useMemo(() => {
    return {
      data: history.data.toReversed(),
      amountHistory: history.amountHistory.toReversed(),
      balanceHistory: history.balanceHistory.toReversed(),
      priceHistory: history.priceHistory.toReversed()
    };
  }, [history]);

  return (
    <Card>
      <CardHeader toggleMode={toggleMode} mode={mode} loadData={loadHistory}/>
      {mode === 'table' && <HistoryTable history={history} error={historyError} loadingHistory={loadingHistory}/>}
      {mode === 'chart' &&
          <HistoryChart history={reversedHistory} error={historyError} loadingHistory={loadingHistory}/>}
    </Card>
  );
};

export default WalletHistory;
