import { ReactNode, useCallback, useState } from 'react';
import { Avatar, Card, Flex } from 'antd';
import { ReactComponent as ArrowDownIcon } from 'resource/icons/arrow/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'resource/icons/arrow/arrow-up.svg';

import UsdValue from 'components/Typography/UsdValue/UsdValue';

import styles from './styles.module.css';

interface PositionCardProps {
  loading: boolean;
  totalValue: number;
  title: ReactNode;
  collapsed: boolean;
  icon?: ReactNode;
  children?: ReactNode;
}

const PositionCard = ({ children, totalValue, loading, title, icon, collapsed }: PositionCardProps) => {
  const [hidden, setHidden] = useState(collapsed);

  const toggleHide = useCallback(() => {
    setHidden(prev => !prev);
  }, []);

  return (
    <Card
      bordered={false}
      className={styles.card}
      loading={loading}
      title={<Flex className={styles.clickable} onClick={toggleHide}>
        {icon && <Avatar style={{ backgroundColor: '#F4F5F9' }} size={40} icon={icon}/>}
        {title}
      </Flex>}
      extra={<div className={styles.clickable} onClick={toggleHide}>
        <UsdValue value={totalValue} strong className={styles.balance}/>
        {hidden ? <ArrowDownIcon/> : <ArrowUpIcon/>}
      </div>}
    >
      {!hidden && children}
    </Card>
  );
};

export default PositionCard;
