import { Flex } from 'antd';

import ChainNameWithIcon from 'components/Icons/ChainNameWithIcon/ChainNameWithIcon';
import CopyableText from 'components/Typography/CopyableText/CopyableText';

import styles from './styles.module.css';

interface WalletTitleProps {
  address: string;
  chain: string;
  chainName: string;
}

const WalletTitle = ({ address, chain, chainName }: WalletTitleProps) => {
  return <Flex vertical className={styles.title} justify={'space-between'}>
    <CopyableText className={styles.address} text={address} size={12} />
    <ChainNameWithIcon className={styles.chain} chain={chain} chainName={chainName} iconSize={16} fontSize={12} />
  </Flex>
};

export default WalletTitle;
