import { useIntl } from 'react-intl';
import { Flex, Typography } from 'antd';

import styles from './styles.module.css';

interface EmptyMessageProps {
  height?: string | number;
}

const EmptyMessage = ({ height = '100%' }: EmptyMessageProps) => {
  const { formatMessage } = useIntl();
  return (
    <Flex className={styles.empty} align="center" justify="center" style={{ height }}>
      <Typography.Text className={styles.emptyText}>
        {formatMessage({ id: 'COMMON_NO_DATA' })}
      </Typography.Text>
    </Flex>
  );
};

export default EmptyMessage;
