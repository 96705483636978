import { Flex } from 'antd';
import { TokenChainItem } from 'model/Portfolio';

import ChainIcon from 'components/Icons/ChainIcon/ChainIcon';
import TokenAmount from 'components/Typography/TokenAmount/TokenAmount';


interface TokenChainAmountProps {
  chains: Array<TokenChainItem>;
  size: number;
}

const TokenChainAmount = ({ chains, size }: TokenChainAmountProps) => {

  return <Flex vertical justify="flex-end">
    {chains.map(chain => (
      <Flex key={chain.chain} justify="flex-end" align="center" gap="small">
        <ChainIcon chain={chain.chain} chainName={chain.chainName} size={size}/>
        <TokenAmount value={chain.amount} size={size}/>
      </Flex>
    ))}
  </Flex>;
};

export default TokenChainAmount;
