import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from 'antd';

interface UsdValueProps {
  value: number;
  className?: string;
  size?: number;
  strong?: boolean;
}

const TokenAmount = ({ className, value, size = 14, strong = false }: UsdValueProps) => {
  const { formatNumber } = useIntl();

  const adjustedValue = useMemo(() => {
    let zeros = 0;
    let tmpAmount = value;
    while (tmpAmount * 10 < 1 && tmpAmount !== 0) {
      zeros++;
      tmpAmount *= 10;
    }

    return formatNumber(value, { style: 'decimal', maximumFractionDigits: zeros + 4 })
  }, [value, formatNumber]);

  return <Typography.Text strong={strong} className={className} style={{ fontSize: size }}>
    {adjustedValue}
  </Typography.Text>;
};

export default TokenAmount;
