import { Flex, Typography } from 'antd';

import ChainNameWithIcon from 'components/Icons/ChainNameWithIcon/ChainNameWithIcon';

import styles from './styles.module.css';

interface PositionTitleProps {
  name: string;
  chain: string;
  chainName?: string;
}

const PositionTitle = ({ name, chain, chainName }: PositionTitleProps) => {
  return <Flex vertical className={styles.title} justify={'space-between'}>
    <Typography.Text>{name}</Typography.Text>
    <ChainNameWithIcon className={styles.chain} chain={chain} chainName={chainName} iconSize={12} fontSize={12}/>
  </Flex>;
};

export default PositionTitle;
