export enum ChartInterval {
  ONE_HOUR = 'ONE_HOUR',
  ONE_DAY = 'ONE_DAY',
  ONE_WEEK = 'ONE_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  ONE_YEAR = 'ONE_YEAR',
  ALL_TIME = 'ALL_TIME',
}

export interface Chart {
  data: Array<ChartItem>;
}

export type ChartItem = {
  timestamp: number,
  usd_value: number
}
