import { Navigate, Route, Routes } from 'react-router-dom';
import LambdaLayout from 'layouts/LambdaLayout/LambdaLayout';
import DeFiPositions from 'page/DeFiPositions/DeFiPositions';
import Overview from 'page/OverviewPage/Overview';
import WalletBalance from 'page/WalletBalance/WalletBalance';

import 'App.css';

const App = () => {
  return (
    <LambdaLayout>
      <Routes>
        <Route path="/" element={<Overview/>}/>
        <Route path="/wallets" element={<WalletBalance/>}/>
        <Route path="/defi-positions" element={<DeFiPositions/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
    </LambdaLayout>
  );
};

export default App;
