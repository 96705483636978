import { SupportedChainItem, SupportedChains } from 'model/Dictionary';
import { dictionaryClient } from 'service/DictionaryService';
import { create } from 'zustand';

interface DictionaryState {
  loading: boolean;
  chainsWithHistory: SupportedChains;
  tokenSymbolsWithHistory: Set<string>;
  error: string;
}

interface DictionaryActions {
  getDictionaries: () => void;
}

const initialState: DictionaryState = {
  loading: false,
  error: '',
  tokenSymbolsWithHistory: new Set<string>(),
  chainsWithHistory: {
    chains: []
  }
};

export const useDictionaryStore = create<DictionaryState & DictionaryActions>((set, get) => ({
  ...initialState,

  getDictionaries: async () => {
    try {
      set({ loading: true, error: undefined });
      const [tokens, protocols] = await Promise.all([
        dictionaryClient.getSupportedTokens({ isSupportHistory: true }),
        dictionaryClient.getSupportedProtocols({ isSupportHistory: true })
      ]);
      const tokensWithHistory = new Set<string>();
      const chainTokens = tokens.data.reduce((acc, token) => {
        token.supported_chains.forEach(chain => {
          if (!acc[chain.id]) {
            acc[chain.id] = { ...chain, tokens: [], protocols: [] };
          }
          acc[chain.id].tokens.push({ id: token.id, name: token.name, symbol: token.symbol, address: token.address });
          tokensWithHistory.add(`${chain.id}-${token.symbol}`);
        });
        return acc;
      }, {} as Record<string, SupportedChainItem>);
      const dictionary = protocols.data.reduce((acc, protocol) => {
        protocol.supported_chains.forEach(chain => {
          if (!acc[chain.id]) {
            acc[chain.id] = { ...chain, tokens: [], protocols: [] };
          }
          acc[chain.id].protocols.push({
            id: protocol.id,
            name: protocol.name,
            supported_positions: protocol.supported_positions
          });
        });
        return acc;
      }, chainTokens);
      set({
        loading: false,
        chainsWithHistory: { chains: Object.values(dictionary) },
        tokenSymbolsWithHistory: tokensWithHistory
      });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  }
}));
