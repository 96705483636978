import { useMemo } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';
import arb from 'resource/icons/crypto/ARB.png';
import bdo from 'resource/icons/crypto/BDO.png';
import chr from 'resource/icons/crypto/CHR.png';
import compe from 'resource/icons/crypto/COMPe.png';
import crv from 'resource/icons/crypto/CRV.png';
import dai from 'resource/icons/crypto/DAI.png';
import eeth from 'resource/icons/crypto/EETH.png';
import eigen from 'resource/icons/crypto/EIGEN.png';
import eth from 'resource/icons/crypto/ETH.png';
import gear from 'resource/icons/crypto/GEAR.png';
import ldo from 'resource/icons/crypto/LDO.png';
import mute from 'resource/icons/crypto/MUTE.png';
import sbdo from 'resource/icons/crypto/SBDO.png';
import snrk from 'resource/icons/crypto/SNRK.png';
import stETH from 'resource/icons/crypto/stETH.png';
import stg from 'resource/icons/crypto/STG.png';
import usdc from 'resource/icons/crypto/USDC.png';
import usdce from 'resource/icons/crypto/USDCe.png';
import usds from 'resource/icons/crypto/USDS.png';
import usdt from 'resource/icons/crypto/USDT.png';
import wbtc from 'resource/icons/crypto/WBTC.png';
import weeth from 'resource/icons/crypto/WEETH.png';
import weth from 'resource/icons/crypto/WETH.png';


interface TokenIconProps {
  token: string;
  size: number;
}

const TokenIcon = ({ token, size = 24 }: TokenIconProps) => {
  const image = useMemo(() => {
    switch (token) {
      case 'eETH':
        return { src: eeth };
      case 'weETH':
        return { src: weeth };
      case 'WETH':
        return { src: weth };
      case 'ETH':
        return { src: eth };
      case 'sBDO':
        return { src: sbdo };
      case 'BDO':
        return { src: bdo };
      case 'WBTC':
        return { src: wbtc };
      case 'stETH':
        return { src: stETH };
      case 'USDS':
        return { src: usds };
      case 'USDT':
        return { src: usdt };
      case 'USDC':
        return { src: usdc };
      case 'DAI':
        return { src: dai };
      case 'STG':
        return { src: stg };
      case 'GEAR':
        return { src: gear };
      case 'EIGEN':
        return { src: eigen };
      case 'LDO':
        return { src: ldo };
      case 'CRV':
        return { src: crv };
      case 'COMP.e':
        return { src: compe };
      case 'USDC.e':
        return { src: usdce };
      case 'ARB':
        return { src: arb };
      case 'CHR':
        return { src: chr };
      case 'MUTE':
        return { src: mute };
        case '$snrk':
        return { src: snrk };
      default:
        return { icon: <QuestionCircleOutlined/> };
    }
  }, [token]);

  return <Tooltip title={token}>
    <Avatar size={size} {...image}/>
  </Tooltip>;
};

export default TokenIcon;
