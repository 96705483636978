import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Table } from 'antd';

import TokenWithIcon from 'components/Icons/TokenWithIcon/TokenWithIcon';
import UsdValue from 'components/Typography/UsdValue/UsdValue';

import { DepositPosition, DepositToken } from '../../model/Position';
import PoolName from '../PoolName/PoolName';
import PositionTypeHeader from '../PositionTypeHeader/PositionTypeHeader';
import TokenBalance from '../TokenBalance/TokenBalance';

interface SupplyTableProps {
  items: Array<DepositPosition>;
}

const type = 'Yield';

const YieldSection = ({ items }: SupplyTableProps) => {
  const { formatMessage } = useIntl();

  const columns = useMemo(() => ([
    {
      title: formatMessage({ id: 'POSITIONS_TABLE_POOL_COLUMN' }),
      dataIndex: 'description',
      key: 'description',
      render: (pool: string) => <TokenWithIcon token={pool} iconSize={16} fontSize={12}/>,
      width: 350
    },
    {
      title: formatMessage({ id: 'POSITIONS_TABLE_TOKEN_COLUMN' }),
      dataIndex: 'pool',
      key: 'pool',
      render: (token: Array<string>) => <PoolName pools={token}/>,
      width: 350
    },
    {
      title: formatMessage({ id: 'POSITIONS_TABLE_BALANCE_COLUMN' }),
      dataIndex: 'supply',
      key: 'supply',
      render: (balance: Array<DepositToken>) => balance.map(b => <TokenBalance {...b} />),
      width: 350
    },
    {
      title: formatMessage({ id: 'POSITIONS_TABLE_VALUE_COLUMN' }),
      dataIndex: 'value',
      key: 'value',
      render: (value: number) => <UsdValue size={12} value={value}/>,
      align: 'right' as const
    }
  ]), [formatMessage]);

  const yields = useMemo(() => {
    return items.filter(position => position.type === type);
  }, [items]);

  if (!yields.length) {
    return <></>;
  }

  return <div>
    <PositionTypeHeader type={type}/>
    <Table dataSource={yields} columns={columns} pagination={false}/>
  </div>;
};

export default YieldSection;
