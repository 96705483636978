import { useIntl } from 'react-intl';
import { Avatar, Flex, Tag, Typography } from 'antd';
import { ReactComponent as FlagIcon } from 'resource/icons/flag.svg';
import fish from 'resource/images/fish.png';

import LoadingCard from 'components/States/LoadingCard/LoadingCard';
import CopyableText from 'components/Typography/CopyableText/CopyableText';

import styles from './styles.module.css';

interface AddressHeaderProps {
  address: string;
  loadingPortfolio: boolean;
}

const AddressInfo = ({ address, loadingPortfolio }: AddressHeaderProps) => {
  const { formatMessage } = useIntl();

  if (loadingPortfolio) {
    return <LoadingCard paddingTop={10} structure={{
      type: 'ROW',
      gap: 'large',
      children: [
        { type: 'AVATAR', size: 100 },
        {
          type: 'COLUMN',
          gap: 'small',
          children: [
            { type: 'TEXT', rows: 3, width: [120, 240, 60] },
          ]
        }
      ]
    }}/>;
  }

  return (
    <Flex className={styles.header}>
      <Flex className={styles.avatar}>
        <Avatar size={100} src={fish}/>
      </Flex>
      <Flex vertical className={styles.addressInfo} justify="start" gap={'small'}>
        <Typography.Text className={styles.addressTitle}
                         strong>{formatMessage({ id: 'DASHBOARD_NO_ID' })}</Typography.Text>
        <Flex align={'center'}>
          <CopyableText className={styles.address} text={address} size={14}/>
          {address && <FlagIcon className={styles.flag}/>}
        </Flex>
        <div>
          {address && <Tag color="success" bordered={false}
                           className={styles.typeTag}>{formatMessage({ id: 'DASHBOARD_ACCOUNT' })}</Tag>}
        </div>
      </Flex>
    </Flex>
  );
};

export default AddressInfo;
