import { useMemo } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';
import arb from 'resource/icons/crypto/ARB.png';
import base from 'resource/icons/crypto/BASE.png';
import celo from 'resource/icons/crypto/CELO.png';
import { ReactComponent as EthereumIcon } from 'resource/icons/crypto/etherium.svg';
import zkSyncEra from 'resource/icons/crypto/ZK_SYNC_ERA.png';


interface ChainIconProps {
  chain: string;
  chainName?: string
  size: number;
}

const ChainIcon = ({ chain, chainName, size }: ChainIconProps) => {
  const image = useMemo(() => {
    switch (chain) {
      case 'ethereum':
        return { icon: <EthereumIcon width={size} height={size}/> };
      case 'arbitrum':
        return { src: arb };
      case 'base':
        return { src: base };
      case 'celo':
        return { src: celo };
      case 'zksync-era':
        return { src: zkSyncEra };
      default:
        return { icon: <QuestionCircleOutlined/>, style: undefined };
    }
  }, [chain, size]);


  return <Tooltip title={chainName || chain}>
    <Avatar size={size} style={{ backgroundColor: 'inherit' }} {...image}/>
  </Tooltip>;
};

export default ChainIcon;
