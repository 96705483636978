import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Flex, TabsProps } from 'antd';

import HeaderTabs from 'components/DataDisplay/HeaderTabs/HeaderTabs';

import WalletHistory from './components/WalletHistory/WalletHistory';
import Wallets from './components/Wallets/Wallets';

const WalletBalance = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('0');

  const items: TabsProps['items'] = useMemo(() => ([
    {
      key: '0',
      label: formatMessage({ id: 'BALANCE_WALLET' }),
      children: <Wallets/>,
    },
    {
      key: '1',
      label: formatMessage({ id: 'BALANCE_HISTORY_MODE' }),
      children: <WalletHistory/>
    }
  ]), [formatMessage]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const chain = queryParams.get('chain');
    if (token && chain) {
      setActiveTab('1')
    }
  }, [setActiveTab, location]);

  return (
    <Flex vertical gap="large">
      <HeaderTabs activeKey={activeTab} onChange={setActiveTab} items={items}/>
    </Flex>
  );
};

export default WalletBalance;
