import { flatten } from 'flat';

const en = {
  COMMON: {
    NO_DATA: 'No data available',
    ERROR: 'Error loading data.',
    REFRESH: 'Refresh',
    DOWNLOAD_CSV: 'Download CSV',
    TOKEN_FILTER: 'Filter by token',
    PROTOCOL_FILTER: 'Filter by protocol',
    POSITION_FILTER: 'Filter by position',
    CHAIN_FILTER: 'Filter by chain',
    VALUE: 'Value',
    PNL_VALUE_USD: 'PnL value USD',
    PRICE_USD: 'Price USD',
    REWARD_USD: 'Reward USD',
    BALANCE_REWARD_USD: 'Balance/Reward USD'
  },
  ERROR: {
    FORBIDDEN: 'Forbidden.',
    UNAUTHORIZED: 'Unauthorized.',
    INTERNAL: 'Something went wrong.',
    STATUS: 'Status {status}'
  },
  NAV_BAR: {
    API: 'API',
    OVERVIEW: 'Overview',
    WALLET_BALANCE: 'Wallet Balances',
    REFRESH: 'Refresh',
    HELP_CENTER: 'Help Center',
    DEFI_POSITIONS: 'DeFi'
  },
  HEADER: {
    SEARCH: 'Search Address',
  },
  DASHBOARD: {
    USER_INFO: 'User info',
    NO_ID: 'No ID',
    ADDRESS: 'Address',
    NET_WORTH: 'Net worth',
    WALLER_TOKENS: 'Wallet Tokens',
    ASSETS: 'Assets',
    WALLET_TOKENS_SEARCH: 'Search wallet tokens',
    DEFI: 'DeFi',
    DEFI_SEARCH: 'Search Address/Chain',
    DEFI_PROFIT: 'DeFi Profit',
    TOKEN_ALLOCATION: 'Token Allocation',
    DEBTS: 'Debts',
    PORTFOLIO_MODE: 'Portfolio',
    ACCOUNT: 'Account',
    NET_WORTH_1_HOUR: '1 hour',
    NET_WORTH_1_DAY: '1 day',
    NET_WORTH_1_WEEK: '1 week',
    NET_WORTH_MONTH: '1 month',
    NET_WORTH_YEAR: '1 year',
    NET_WORTH_ALL_TIME: 'All Time',
    DEFI_PROTOCOL_COLUMN: 'Protocol',
    DEFI_CHAIN_COLUMN: 'Chain',
    DEFI_VALUE_COLUMN: 'Value',
    TOKEN_ALLOCATION_HIDE_CHAINS: 'Hide',
    TOKEN_ALLOCATION_SHOW_CHAINS: 'Unfold {count} chains',
  },
  BALANCE: {
    WALLET: 'Wallets',
    HISTORY_MODE: 'History mode',
    PNL: 'PnL',
    HISTORY: {
      TABLE_TOKEN_COLUMN: 'Token',
      TABLE_DATE_COLUMN: 'Date',
      TABLE_BLOCK_COLUMN: 'Block',
      TABLE_PRICE_COLUMN: 'Price',
      TABLE_AMOUNT_COLUMN: 'Balance',
      TABLE_VALUE_COLUMN: 'Value'
    },
    TABLE: {
      TOKEN_COLUMN: 'Token',
      PRICE_COLUMN: 'Price',
      AMOUNT_COLUMN: 'Amount',
      VALUE_COLUMN: 'Value'
    }
  },
  POSITIONS: {
    DEFI_POSITIONS: 'DeFi positions',
    HISTORY_MODE: 'History mode',
    PNL: 'PnL',
    TABLE: {
      TOKEN_COLUMN: 'Token',
      HEALTH_RATE_COLUMN: 'Health Rate',
      POOL_COLUMN: 'Pool',
      BALANCE_COLUMN: 'Balance',
      SUPPLY_COLUMN: 'Supplied',
      BORROW_COLUMN: 'Borrowed',
      REWARDS_COLUMN: 'Rewards',
      VALUE_COLUMN: 'Value',
    },
    HISTORY: {
      TABLE_TOKEN_COLUMN: 'Token',
      TABLE_DATE_COLUMN: 'Date',
      TABLE_BLOCK_COLUMN: 'Block',
      TABLE_POSITION_TYPE_COLUMN: 'Position type',
      TABLE_PRICE_COLUMN: 'Price',
      TABLE_AMOUNT_COLUMN: 'Balance',
      TABLE_REWARD_COLUMN: 'Rewards',
      TABLE_VALUE_COLUMN: 'Value'
    }
  }
};

const messages = {
  en: flatten<Record<string, any>, Record<string, string>>(en, { delimiter: '_' })
};

export default messages;
