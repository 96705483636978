import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowTopRightIcon } from 'resource/icons/arrow/top-right-arrow.svg';
import { useDictionaryStore } from 'store/dictionaryStore';

import styles from './styles.module.css';


interface HistoryLinkProps {
  token?: string;
  iconSize?: number;
  chain?: string;
}

const HistoryLink = ({ token, chain, iconSize = 12 }: HistoryLinkProps) => {
  const navigate = useNavigate();
  const tokensWithHistory = useDictionaryStore(state => state.tokenSymbolsWithHistory);

  const navigateToHistory = useCallback(() => {
    navigate(`/wallets?token=${token}&chain=${chain}`);
  }, [token, chain, navigate]);

  if (!chain || !token || !tokensWithHistory.has(`${chain}-${token}`)) {
    return null;
  }

  return <ArrowTopRightIcon className={styles.historyButton}
                            width={iconSize} height={iconSize}
                            onClick={navigateToHistory}/>;
};

export default HistoryLink;
