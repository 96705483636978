import { Chart } from 'model/Chart';
import { netWorthClient } from 'service/NetWorthService';
import { create } from 'zustand';

import { defiProfitClient } from '../../service/DefiProfitService';

interface StatusState {
  error: string;

  loadingNetWorth: boolean;
  netWorth: Chart;

  loadingDefiProfit: boolean;
  defiProfit: Chart;
}

interface StatusActions {
  reset: () => void;
  loadNetworth: (address: string, interval: string) => void;
  loadDefiProfit: (address: string, interval: string) => void;
}

const initialState: StatusState = {
  error: '',

  loadingNetWorth: false,
  netWorth: { data: [] },

  loadingDefiProfit: false,
  defiProfit: { data: [] }
};

export const useDashboardStore = create<StatusState & StatusActions>((set, get) => ({
  ...initialState,

  loadNetworth: async (address: string, interval: string) => {
    if (!address) {
      get().reset();
      return;
    }

    try {
      set({ loadingNetWorth: true });
      const netWorth = await netWorthClient.getNetWorth(address, interval);
      set({
        loadingNetWorth: false,
        error: '',
        netWorth
      });
    } catch (error) {
      set({
        error: error.message,
        netWorth: { data: [] },
        loadingNetWorth: false
      });
    }
  },

  loadDefiProfit: async (address: string, interval: string) => {
    if (!address) {
      get().reset();
      return;
    }

    try {
      set({ loadingDefiProfit: true });
      const defiProfit = await defiProfitClient.getDefiProfit(address, interval);
      set({
        loadingDefiProfit: false,
        error: '',
        defiProfit
      });
    } catch (error) {
      set({
        error: error.message,
        defiProfit: { data: [] },
        loadingDefiProfit: false
      });
    }
  },

  reset: () => {
    set(initialState);
  }
}));
