import { Flex, Tooltip, Typography } from 'antd';
import { ReactComponent as QuestionIcon } from 'resource/icons/question.svg';

import styles from './styles.module.css';

interface CardTitleProps {
  title: string;
  help?: string;
}

const CardTitle = ({ title, help }: CardTitleProps) => {
  return <Flex gap={'small'} align="center">
    <Typography.Text className={styles.title}>
      {title}
    </Typography.Text>
    {help && (
      <Tooltip title={help}>
        <QuestionIcon className={styles.help}/>
      </Tooltip>
    )}
  </Flex>;
};

export default CardTitle;
