import { ReactNode } from 'react';
import { Button } from 'antd';

import styles from './styles.module.css'

interface ButtonOutlinedProps {
  text: string;
  icon?: ReactNode;
  onClick?: () => void;
}

const ButtonOutlined = ({ text, icon, onClick }: ButtonOutlinedProps) => {
  return (
    <Button className={styles.outlinedButton} color="primary" variant="outlined" icon={icon} onClick={onClick}>
      {text}
    </Button>
  );
};

export default ButtonOutlined;
