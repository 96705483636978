import { useMemo } from 'react';
import { Flex } from 'antd';

import styles from './styles.module.css';

interface ItemsRatioBarProps {
  totalValue: number;
  values: Array<number>;
  colors: Array<string>
}

const ItemsRatioBar = ({ totalValue, values, colors }: ItemsRatioBarProps) => {
  const percentages = useMemo(() => {
    return values.map(value => Math.round(value / totalValue * 100));
  }, [totalValue, values]);

  const rest = useMemo(() => (
    100 - percentages.reduce((acc, value) => acc + value, 0)
  ), [percentages]);

  return <Flex className={styles.itemsRatioBar} vertical align={'center'}>
    {rest > 0 && <div className={styles.valueBar} style={{ height: `${rest}%`, backgroundColor: '#7857FF' }}></div>}
    {percentages.map((value, index) => (
      <div key={`${index}`} className={styles.valueBar} style={{ height: `${value}%`, backgroundColor: colors[index] }}></div>
    ))}
  </Flex>;
};

export default ItemsRatioBar;
