import { Flex } from 'antd';
import { useRootStore } from 'store/portfolioStore';

import PositionCard from 'components/DataDisplay/PositionCard/PositionCard';
import ProtocolIcon from 'components/Icons/ProtocolIcon/ProtocolIcon';
import EmptyMessage from 'components/States/EmptyMessage/EmptyMessage';
import ErrorMessage from 'components/States/ErrorMessage/ErrorMessage';
import LoadingCard from 'components/States/LoadingCard/LoadingCard';

import PositionContent from './components/PositionContent/PositionContent';
import PositionTitle from './components/PositionTitle/PositionTitle';

const DeFiPortfolio = () => {
  const defis = useRootStore(state => state.defis);
  const error = useRootStore(state => state.error);
  const loadingPortfolio = useRootStore(state => state.loadingPortfolio);

  if (loadingPortfolio) {
    return <LoadingCard structure={{
      type: 'COLUMN',
      gap: 'small',
      children: [
        { type: 'NODE', width: '100%' },
        { type: 'NODE', width: '100%' },
        { type: 'NODE', width: '100%' }
      ]
    }}/>;
  }

  if (error) {
    return <ErrorMessage/>;
  }

  return (
    <Flex vertical gap="large">
      {!defis.length && <EmptyMessage/>}
      {defis.map((protocol, index) => (
        <PositionCard key={`${protocol.protocol_chain}_${protocol.protocol_id}`}
                      loading={loadingPortfolio}
                      collapsed={index !== 0}
                      title={<PositionTitle name={protocol.protocol_name} chain={protocol.protocol_chain}
                                            chainName={protocol.protocol_chain_name}/>}
                      totalValue={protocol.value_usd}
                      icon={<ProtocolIcon protocol={protocol.protocol_name}/>}>
          <PositionContent chain={protocol.protocol_chain} positions={protocol.positions}/>
        </PositionCard>
      ))}
    </Flex>
  );
};

export default DeFiPortfolio;
