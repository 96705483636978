import { Flex } from 'antd';

import PnlLink from 'components/Buttons/PnlLink/PnlLink';
import TokenIcon from 'components/Icons/TokenIcon/TokenIcon';
import TokenAmount from 'components/Typography/TokenAmount/TokenAmount';
import UsdValue from 'components/Typography/UsdValue/UsdValue';

import styles from './styles.module.css';

interface TokenBalanceProps {
  token: string;
  chain: string;
  amount: number;
  usdValue: number;
  history: Array<string>
}

const TokenBalance = ({ token, chain, amount, usdValue, history }: TokenBalanceProps) => {
  return <Flex gap={'small'} align={'center'}>
    <TokenIcon token={token} size={16}/>
    <PnlLink history={history} chain={chain} iconSize={12}/>
    <TokenAmount value={amount} size={12}/>
    <div className={styles.usdValue}>
      [<UsdValue value={usdValue} size={12} className={styles.usdValue}/>]
    </div>
  </Flex>;
};

export default TokenBalance;
