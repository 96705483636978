import React, { useCallback } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { RiseOutlined } from '@ant-design/icons';

import styles from './styles.module.css';


interface HistoryLinkProps {
  history: Array<string>;
  iconSize?: number;
  chain: string;
}

const PnlLink = ({ history, chain, iconSize = 12 }: HistoryLinkProps) => {
  const navigate = useNavigate();

  const navigateToHistory = useCallback(() => {
    navigate({
      pathname: `/defi-positions`,
      search: createSearchParams({ history, chain }).toString()
    });
  }, [history, chain, navigate]);

  if (!chain || !history?.length) {
    return null;
  }

  return <RiseOutlined className={styles.pnlButton}
                       onClick={navigateToHistory}
                       size={iconSize}/>;
};

export default PnlLink;
