import { Flex, Skeleton } from 'antd';

import styles from './styles.module.css';

interface SkeletonItem {
  type: 'NODE' | 'COLUMN' | 'ROW' | 'AVATAR' | 'TEXT';
  gap?: string;
  size?: number;
  width?: string | number | number[] | string[];
  height?: number;
  rows?: number;
  children?: Array<SkeletonItem>;
}

interface LoadingCardProps {
  paddingTop?: number;
  structure: SkeletonItem;
}

const LoadingCard = ({ structure, paddingTop = 0 }: LoadingCardProps) => {
  if (structure.type === 'COLUMN') {
    return <Flex vertical gap={structure.gap} style={{ paddingTop }}>
      {structure.children?.map((child, index) => <LoadingCard key={index} structure={child}/>)}
    </Flex>;
  }

  if (structure.type === 'ROW') {
    return <Flex gap={structure.gap} style={{ paddingTop }}>
      {structure.children?.map((child, index) => <LoadingCard key={index} structure={child}/>)}
    </Flex>;
  }

  if (structure.type === 'TEXT') {
    return <Skeleton title={false} paragraph={{ rows: structure.rows, width: structure.width }}
                     className={styles.paragraph}
                     active={true}/>;
  }

  if (structure.type === 'AVATAR') {
    return <Skeleton.Avatar active={true} size={structure.size}/>;
  }

  if (structure.type === 'NODE') {
    return <Flex style={{ width: structure.width as number }} className={styles.node}><Skeleton.Node active={true}
                                                                                                     style={{
                                                                                                       height: structure.height,
                                                                                                       width: '100%'
                                                                                                     }}/></Flex>;
  }

  return null;
};

export default LoadingCard;
