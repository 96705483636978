import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ChainTokenItem } from 'model/Dictionary';
import { LabelInValueType } from 'rc-select/lib/Select';

import TokenWithIcon from 'components/Icons/TokenWithIcon/TokenWithIcon';
import Select from 'components/Inputs/Select/Select';

interface TokenFilterProp {
  disabled?: boolean;
  tokens?: Array<ChainTokenItem>;
  selectedToken?: ChainTokenItem;
  onChange: (item?: ChainTokenItem) => void;
}

const TokenFilter = ({ onChange, tokens, selectedToken, disabled }: TokenFilterProp) => {
  const { formatMessage } = useIntl();

  const options = useMemo(() => {
    return (tokens || []).map(token => ({
        ...token,
        label: token.symbol,
        value: token.id
      }));
  }, [tokens]);

  const optionRender = useCallback((option: { data: ChainTokenItem }) => {
    return <TokenWithIcon token={option.data.symbol} iconSize={16}/>;
  }, []);

  const labelRender = useCallback((option: LabelInValueType) => {
    return <TokenWithIcon token={option.label as string} iconSize={16}/>;
  }, []);

  const onTokenChange = useCallback((_key: string, item?: ChainTokenItem | ChainTokenItem[]) => {
    if (item) {
      Array.isArray(item) ? onChange(item[0]) : onChange(item);
    } else {
      onChange(undefined);
    }
  }, [onChange]);

  return <Select placeholder={formatMessage({ id: 'COMMON_TOKEN_FILTER' })}
                 value={selectedToken?.id}
                 options={options}
                 optionRender={optionRender}
                 labelRender={labelRender}
                 showSearch={true}
                 disabled={disabled}
                 width={220}
                 onChange={onTokenChange}/>;
};

export default TokenFilter;
