import { useMemo } from 'react';
import { Flex } from 'antd';
import { DefiPosition } from 'model/Portfolio';

import { DepositPosition } from '../../model/Position';
import DepositSection from '../DepositSection/DepositSection';
import FarmingSection from '../FarmingSection/FarmingSection';
import LendingSection from '../LendingSection/LendingSection';
import LiquidityPoolSection from '../LiquidityPoolSection/LiquidityPoolSection';
import StakedSection from '../StakedSection/StakedSection';
import YieldSection from '../YieldSection/YieldSection';

interface PositionContentProps {
  chain: string;
  positions: Array<DefiPosition>;
}

const PositionContent = ({ chain, positions }: PositionContentProps) => {

  const convertedPositions = useMemo(() => {
    return positions
      .map(item => {
        const position: DepositPosition = {
          type: item.name,
          value: 0,
          description: item.description,
          health_rate: item.health_rate,
          pool: [],
          supply: [],
          rewards: [],
          borrow: []
        };
        item.tokens
          .filter(token => token.type === 'SUPPLY')
          .forEach(token => {
            const price = token.amount * token.price;
            position.supply.push({
              key: `${token.symbol}_${token.amount}`,
              token: token.symbol,
              chain: chain,
              amount: token.amount,
              usdValue: price,
              history: token.history_tokens
            });
            position.value += price;
            position.pool.push(token.symbol);
          });

        item.tokens
          .filter(token => token.type === 'REWARD')
          .forEach(token => {
            const price = token.amount * token.price;
            position.rewards.push({
              key: `${token.symbol}_${token.amount}`,
              token: token.symbol,
              chain: chain,
              amount: token.amount,
              usdValue: price,
              history: token.history_tokens
            });
          });

        item.tokens
          .filter(token => token.type === 'BORROW')
          .forEach(token => {
            const usdValue = (token.amount * token.price);
            position.borrow.push({
              key: `${token.symbol}_${token.amount}`,
              token: token.symbol,
              chain: chain,
              amount: token.amount,
              usdValue: usdValue,
              history: token.history_tokens
            });
          });
        return position;
      });
  }, [chain, positions]);

  return (
    <Flex vertical gap="large">
      <FarmingSection items={convertedPositions}/>
      <StakedSection items={convertedPositions}/>
      <YieldSection items={convertedPositions}/>
      <LendingSection items={convertedPositions}/>
      <LiquidityPoolSection items={convertedPositions}/>
      <DepositSection items={convertedPositions}/>
    </Flex>
  );
};

export default PositionContent;
