import instance from 'config/AxiosConfig';

import { Chart } from '../model/Chart';

export class DefiProfitClient {
  private static _instance = new DefiProfitClient();

  static get instance(): DefiProfitClient {
    return this._instance;
  }

  readonly host: string;

  constructor() {
    this.host = window.config.API_URL;
  }

  public async getDefiProfit(address: string, interval: string): Promise<Chart> {
    const url = `${this.host}/api/v1/addresses/${address}/defi-profits?interval=${interval}`;
    const result = await instance.get<Chart>(url);
    return result.data;
  }
}

export const defiProfitClient = DefiProfitClient.instance;
