import instance from 'config/AxiosConfig';

import { Portfolio } from '../model/Portfolio';

export class PortfolioClient {
  private static _instance = new PortfolioClient();

  static get instance(): PortfolioClient {
    return this._instance;
  }

  readonly host: string;

  constructor() {
    this.host = window.config.API_URL;
  }

  public async getPortfolio(address: string): Promise<Portfolio> {
    const url = `${this.host}/api/v1/addresses/${address}/portfolio`;
    const result = await instance.get<Portfolio>(url);
    return result.data;
  }
}

export const portfolioClient = PortfolioClient.instance;
