import React, { useMemo } from 'react';
import { Table } from 'antd';

import LoadingCard from '../LoadingCard/LoadingCard';

interface LoadingCardProps {
  columns: any[];
}

const LoadingTable = ({ columns }: LoadingCardProps) => {
  const data = useMemo(() => {
    return Array.from(Array(10).keys());
  }, []);

  const skeletonColumns = useMemo(() => {
    return columns.map(column => ({
      ...column,
      render: () => <LoadingCard structure={{ type: 'TEXT' }} />
    }))
  }, [columns])

  return <Table dataSource={data} columns={skeletonColumns}/>;
};

export default LoadingTable;
