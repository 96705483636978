import { Tiny } from '@ant-design/charts';
import { Flex } from 'antd';

import styles from './styles.module.css';

interface MicroLineChartProps {
  data: Array<{[key: string]: string | number}>,
  xField: string;
  yField: string;
  color: string;
}

const MicroLineChart = ({ data, xField, yField, color }: MicroLineChartProps) => {
  if (!data.length) {
    return <Flex className={styles.emptyChartContainer} justify="center" align="center">
      <div className={styles.line}></div>
    </Flex>;
  }

  const config = {
    data: data,
    colorField: '',
    height: 10,
    width: 60,
    xField,
    yField,
    autoFit: false,
    style: { lineWidth: 2 },
    scale: { color: { range: [color] } }
  };
  return <div><Tiny.Line {...config} /></div>;
};

export default MicroLineChart;
