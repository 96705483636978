import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Flex, TabsProps } from 'antd';

import HeaderTabs from 'components/DataDisplay/HeaderTabs/HeaderTabs';

import DefiPnl from './components/DefiPnl/DefiPnl';
import DeFiPortfolio from './components/DeFiPortfolio/DeFiPortfolio';

const DeFiPositions = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('0');

  const items: TabsProps['items'] = useMemo(() => ([
    {
      key: '0',
      label: formatMessage({ id: 'POSITIONS_DEFI_POSITIONS' }),
      children: <DeFiPortfolio/>,
    },
    {
      key: '1',
      label: formatMessage({ id: 'POSITIONS_PNL' }),
      children: <DefiPnl />
    }
  ]), [formatMessage]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.getAll('history');
    const chain = queryParams.get('chain');
    if (token?.length && chain) {
      setActiveTab('1');
    }
  }, [setActiveTab, location]);

  return (
    <Flex vertical gap="large">
      <HeaderTabs items={items} activeKey={activeTab} onChange={setActiveTab}/>
    </Flex>
  );
};

export default DeFiPositions;
