import { Flex, Typography } from 'antd';

import HistoryLink from 'components/Buttons/HistoryLink/HistoryLink';
import TokenIcon from 'components/Icons/TokenIcon/TokenIcon';

interface TokenWithIconProps {
  token: string;
  iconSize?: number;
  fontSize?: number;
  chain?: string;
}

const TokenWithIcon = ({ token, chain, iconSize = 24, fontSize = 12 }: TokenWithIconProps) => {
  return <Flex gap={'small'} align={'center'}>
    <TokenIcon token={token} size={iconSize}/>
    <Typography.Text style={{ fontSize }}>{token}</Typography.Text>
    <HistoryLink iconSize={fontSize} token={token} chain={chain} />
  </Flex>;
};

export default TokenWithIcon;
