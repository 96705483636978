import { Pnl } from 'model/Wallet';
import { walletClient } from 'service/WalletService';
import { create } from 'zustand';

interface DefiHistoryState {
  pnlError: string;
  loadingPnl: boolean;
  pnl: Pnl;
}

interface DefiHistoryActions {
  loadDefiPnl: (address: string, tokens: Array<string>, chain: string) => void;
  reset: () => void;
}

const initialState: DefiHistoryState = {
  pnlError: '',
  loadingPnl: false,
  pnl: { data: { pnl: []}}
};

export const useDefiStore = create<DefiHistoryState & DefiHistoryActions>((set, get) => ({
  ...initialState,

  loadDefiPnl: async (address: string, tokens: Array<string>, chain: string) => {
    if (!address) {
      get().reset();
      return;
    }

    try {
      set({ loadingPnl: true, pnl: { data: { pnl: []}} });
      const pnl = await walletClient.getTokenPnl({ address, tokens, chain });
      const sortedPnls = pnl.data.pnl.map(pnl => {
        const sortedPoints = pnl.points.sort((a, b) => a.timestamp - b.timestamp)
        const balanceRewardPoints = sortedPoints.reduce((acc: Array<any>, point: any) => {
          acc.push({timestamp: point.timestamp, value: point.balance_usd, type: 'Balance USD'});
          acc.push({timestamp: point.timestamp, value: point.rewards_usd, type: 'Reward USD'});
          return acc;
        }, [] as any);
        const pnlPoints = sortedPoints.map(point => {
          return { timestamp: point.timestamp, value: point.value, type: 'PnL value USD' };
        }, [] as any);
        return {
          points: sortedPoints,
          balanceRewardPoints: balanceRewardPoints,
          pnlPoints: pnlPoints,
          token: pnl.token
        }
      })
      set({
        loadingPnl: false,
        pnlError: '',
        pnl: {
          data: {
            pnl: sortedPnls
          }
        }
      });
    } catch (error) {
      set({
        pnlError: error.message,
        pnl: { data: { pnl: [] } },
        loadingPnl: false
      });
    }
  },

  reset: () => {
    set(initialState);
  }
}));
