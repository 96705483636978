import { useEffect } from 'react';
import { Flex } from 'antd';
import NetWorthChart from 'page/OverviewPage/compnents/NetWorthChart/NetWorthChart';
import StatsCards from 'page/OverviewPage/compnents/StatsCards/StatsCards';
import TokenAllocation from 'page/OverviewPage/compnents/TokenAllocation/TokenAllocation';
import WalletTokens from 'page/OverviewPage/compnents/WalletTokens/WalletTokens';

import DeFi from './compnents/DeFi/DeFi';
import DefiProfitChart from './compnents/DefiProfitChart/DefiProfitChart';
import { useDashboardStore } from './store';

const Overview = () => {
  const reset = useDashboardStore(state => state.reset);

  useEffect(() => {
    return reset;
  }, [reset]);

  return (
    <Flex vertical gap="middle">
      <StatsCards/>
      <NetWorthChart/>
      <TokenAllocation/>
      <WalletTokens/>
      <DefiProfitChart/>
      <DeFi/>
    </Flex>
  );
};

export default Overview;
