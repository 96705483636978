import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Flex, Table } from 'antd';
import { ReactComponent as WalletIcon } from 'resource/icons/wallet.svg';
import { useRootStore } from 'store/portfolioStore';

import PositionCard from 'components/DataDisplay/PositionCard/PositionCard';
import TokenWithIcon from 'components/Icons/TokenWithIcon/TokenWithIcon';
import EmptyMessage from 'components/States/EmptyMessage/EmptyMessage';
import ErrorMessage from 'components/States/ErrorMessage/ErrorMessage';
import LoadingCard from 'components/States/LoadingCard/LoadingCard';
import TokenAmount from 'components/Typography/TokenAmount/TokenAmount';
import UsdValue from 'components/Typography/UsdValue/UsdValue';

import WalletTitle from './components/WalletTitle/WalletTitle';


const Wallets = () => {
  const { formatMessage } = useIntl();
  const address = useRootStore(state => state.address);
  const error = useRootStore(state => state.error);
  const loadingPortfolio = useRootStore(state => state.loadingPortfolio);
  const chainTokens = useRootStore(state => state.chainTokens);

  const getColumns = useCallback((chain: string) => ([
    {
      title: formatMessage({ id: 'BALANCE_TABLE_TOKEN_COLUMN' }),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (token: string) => <TokenWithIcon token={token} chain={chain} iconSize={16} fontSize={12}/>,
      align: 'left' as const
    },
    {
      title: formatMessage({ id: 'BALANCE_TABLE_PRICE_COLUMN' }),
      dataIndex: 'price',
      key: 'price',
      render: (value: number) => <UsdValue value={value} size={12}/>,
      width: 100,
      align: 'right' as const
    },
    {
      title: formatMessage({ id: 'BALANCE_TABLE_AMOUNT_COLUMN' }),
      dataIndex: 'amount',
      key: 'amount',
      render: (value: number) => <TokenAmount value={value} size={12}/>,
      width: 250,
      align: 'right' as const
    },
    {
      title: formatMessage({ id: 'BALANCE_TABLE_VALUE_COLUMN' }),
      dataIndex: 'usd_value',
      key: 'usd_value',
      render: (value: number) => <UsdValue value={value} size={12}/>,
      width: 100,
      align: 'right' as const
    }
  ]), [formatMessage]);

  if (loadingPortfolio) {
    return <LoadingCard structure={{
      type: 'COLUMN',
      gap: 'small',
      children: [
        { type: 'NODE', width: '100%' },
        { type: 'NODE', width: '100%' },
        { type: 'NODE', width: '100%' }
      ]
    }}/>;
  }

  if (error) {
    return <ErrorMessage/>;
  }

  return (
    <Flex vertical gap="middle">
      {!chainTokens.length && <EmptyMessage/>}
      {chainTokens.map(wallet => (
        <PositionCard key={wallet.chain}
                      collapsed={false}
                      loading={loadingPortfolio}
                      title={<WalletTitle address={address} chain={wallet.chain} chainName={wallet.chainName}/>}
                      totalValue={wallet.valueUsd}
                      icon={<WalletIcon width={24} height={24}/>}>
          <Table dataSource={wallet.tokens} columns={getColumns(wallet.chain)} pagination={false}/>
        </PositionCard>
      ))}
    </Flex>
  );
};

export default Wallets;
