import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Table } from 'antd';

import RoundedValue from 'components/Typography/RoundedValue/RoundedValue';

import { DepositPosition, DepositToken } from '../../model/Position';
import PositionTypeHeader from '../PositionTypeHeader/PositionTypeHeader';
import TokenBalance from '../TokenBalance/TokenBalance';

interface SupplyTableProps {
  items: Array<DepositPosition>;
}

const type = 'Lending';

const LendingSection = ({ items }: SupplyTableProps) => {
  const { formatMessage } = useIntl();

  const columns = useMemo(() => ([
    {
      title: formatMessage({ id: 'POSITIONS_TABLE_HEALTH_RATE_COLUMN' }),
      dataIndex: 'health_rate',
      key: 'health_rate',
      render: (value: number) => value ? <RoundedValue value={value} size={12}/> : 'Coming soon...',
      width: 350
    },
    {
      title: formatMessage({ id: 'POSITIONS_TABLE_SUPPLY_COLUMN' }),
      dataIndex: 'supply',
      key: 'supply',
      render: (balance: Array<DepositToken>) => balance.map(b => <TokenBalance {...b} key={b.key} />),
      width: 350
    },
    {
      title: formatMessage({ id: 'POSITIONS_TABLE_BORROW_COLUMN' }),
      dataIndex: 'borrow',
      key: 'borrow',
      render: (balance: Array<DepositToken>) => balance.map(b => <TokenBalance {...b} key={b.key} />),
      width: 350
    },
    {
      title: formatMessage({ id: 'POSITIONS_TABLE_REWARDS_COLUMN' }),
      dataIndex: 'rewards',
      key: 'rewards',
      render: (balance: Array<DepositToken>) => balance.map(b => <TokenBalance {...b} key={b.key} />),
      width: 350
    }
  ]), [formatMessage]);

  const lendings = useMemo(() => {
    return items.filter(position => position.type === type);
  }, [items]);

  if (!lendings.length) {
    return <></>;
  }

  return <div>
    <PositionTypeHeader type={type}/>
    <Table dataSource={lendings} columns={columns} pagination={false}/>
  </div>;
};

export default LendingSection;
