import React, { useMemo } from 'react';
import { Table } from 'antd';

import EmptyMessage from 'components/States/EmptyMessage/EmptyMessage';
import ErrorMessage from 'components/States/ErrorMessage/ErrorMessage';
import LoadingTable from 'components/States/LoadingTable/LoadingTable';

import styles from './styles.module.css';

interface ScrollableTableProps {
  loading: boolean;
  columns: any[];
  error?: string;
  data: any[];
  scrollSize: number;
  pagination?: boolean;
}

const ScrollableTable = ({ columns, data, scrollSize, loading, error, pagination = false }: ScrollableTableProps) => {
  const paginationConfig = useMemo(() => {
    if (!pagination) {
      return false;
    }

    return {
      pageSizeOptions: [5, 10, 15, 20],
      defaultPageSize: 100,
      locale: { items_per_page: '' },
      total: 200,
      showSizeChanger: { className: styles.pager },
      disabled: true
    };
  }, [pagination]);


  if (loading) {
    return <LoadingTable columns={columns}/>;
  }

  if (error) {
    return <ErrorMessage height={86}/>;
  }

  if (!data.length) {
    return <div style={{ height: 120 }}>
      <EmptyMessage/>
    </div>;
  }

  return (
    <Table dataSource={data}
           className={styles.scrollableTable}
           columns={columns}
           scroll={{ y: scrollSize }}
           pagination={paginationConfig}
    />
  );
};

export default ScrollableTable;
