import ReactDOM from 'react-dom/client';
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { App, ConfigProvider } from 'antd';
import Lambda from 'App';
import { appTheme } from 'config/Theme';
import messages from "resource/localization";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <App>
    <ConfigProvider theme={appTheme}>
      <IntlProvider locale="en" defaultLocale="en" messages={messages['en']}>
        <BrowserRouter>
          <Lambda/>
        </BrowserRouter>
      </IntlProvider>
    </ConfigProvider>
  </App>
);
