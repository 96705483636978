import React, { LegacyRef, type ReactNode, useCallback } from 'react';
import { Input } from 'antd';
import { ReactComponent as SearchIcon } from 'resource/icons/search.svg';

import styles from 'components/Inputs/Search/styles.module.css';

interface SearchProps {
  loading?: boolean;
  inputRef?: LegacyRef<any>;
  onSearch: (data: string) => void;
  placeholder: string;
  defaultValue?: string;
  color?: 'gray' | 'white',
  customClass?: string,
  hotKey?: {
    component?: ReactNode
  }
}

const Search = ({
                  inputRef,
                  onSearch,
                  defaultValue,
                  placeholder,
                  hotKey,
                  customClass = '',
                  loading = false
                }: SearchProps) => {
  const handleSearch = useCallback((data: string) => {
    onSearch(data);
  }, [onSearch]);

  return (
    <Input.Search
      ref={inputRef}
      allowClear
      defaultValue={defaultValue}
      className={`${styles.search} ${styles.whiteSearch} ${customClass}`}
      prefix={<SearchIcon style={{ color: 'rgba(0,0,0,.25)', marginRight: 5 }}/>}
      suffix={hotKey?.component}
      placeholder={placeholder}
      onSearch={handleSearch}
      loading={loading}
    />
  );
};

export default Search;
