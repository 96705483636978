import { useIntl } from 'react-intl';
import { Flex } from 'antd';
import { useRootStore } from 'store/portfolioStore';

import LoadingCard from '../../../../components/States/LoadingCard/LoadingCard';
import { useDashboardStore } from '../../store';

import CommingSoonCard from './components/CommingSoonCard/CommingSoonCard';
import StatsCard from './components/StatsCard/StatsCard';

import styles from './styles.module.css';

const StatsCards = () => {
  const { formatMessage } = useIntl();
  const totalNetWorth = useRootStore(state => state.totalNetWorth);
  const loadingPortfolio = useRootStore(state => state.loadingPortfolio);
  const loadingNetWorth = useDashboardStore(state => state.loadingNetWorth);
  const netWorth = useDashboardStore(state => state.netWorth);
  const loading = loadingPortfolio || loadingNetWorth;

  if (loading) {
    return <LoadingCard structure={{
      type: 'ROW',
      gap: 'small',
      children: [
        { type: 'NODE', width: '20%', height: 143 },
        { type: 'NODE', width: '20%', height: 143 },
        { type: 'NODE', width: '20%', height: 143 },
        { type: 'NODE', width: '20%', height: 143 },
        { type: 'NODE', width: '20%', height: 143 }
      ]
    }}/>;
  }


  return (
    <Flex className={styles.statsBar} gap={'middle'}>
      <StatsCard
        title={formatMessage({ id: 'DASHBOARD_NET_WORTH' })}
        help={formatMessage({ id: 'DASHBOARD_NET_WORTH' })}
        total={totalNetWorth}
        data={netWorth.data}
        xField={'timestamp'}
        yField={'usd_value'}
        loading={loading}
      />
      <CommingSoonCard title={formatMessage({ id: 'DASHBOARD_ASSETS' })}
                       help={formatMessage({ id: 'DASHBOARD_ASSETS' })} loading={loading}/>
      <CommingSoonCard title={formatMessage({ id: 'DASHBOARD_DEFI' })}
                       help={formatMessage({ id: 'DASHBOARD_DEFI' })} loading={loading}/>
      <CommingSoonCard title={formatMessage({ id: 'DASHBOARD_DEFI_PROFIT' })}
                       help={formatMessage({ id: 'DASHBOARD_DEFI_PROFIT' })} loading={loading}/>
      <CommingSoonCard title={formatMessage({ id: 'DASHBOARD_DEBTS' })}
                       help={formatMessage({ id: 'DASHBOARD_DEBTS' })} loading={loading}/>
    </Flex>
  );
};

export default StatsCards;
