import { Flex } from 'antd';
import { TokenChainItem } from 'model/Portfolio';

import ChainIcon from 'components/Icons/ChainIcon/ChainIcon';
import UsdValue from 'components/Typography/UsdValue/UsdValue';

import HistoryLink from '../../../../../components/Buttons/HistoryLink/HistoryLink';


interface TokenChainAmountProps {
  token: string;
  chains: Array<TokenChainItem>;
  size: number;
}

const TokenChainValue = ({ token, chains, size }: TokenChainAmountProps) => {

  return <Flex vertical justify="flex-end">
    {chains.map(chain => (
      <Flex key={chain.chain} justify="flex-end" align="center" gap="small">
        <ChainIcon chain={chain.chain} chainName={chain.chainName} size={size}/>
        <UsdValue value={chain.valueUsd} size={size}/>
        <HistoryLink iconSize={12} chain={chain.chain} token={token}/>
      </Flex>
    ))}
  </Flex>;
};

export default TokenChainValue;
