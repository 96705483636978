import { useCallback, useEffect, useMemo } from 'react';
import { Card, Flex } from 'antd';

import PnlSection from 'components/DataDisplay/PnlSection/PnlSection';

import EmptyMessage from '../../../../components/States/EmptyMessage/EmptyMessage';
import LoadingCard from '../../../../components/States/LoadingCard/LoadingCard';
import CardHeader from '../CardHeader/CardHeader';
import { useDefiStore } from '../store';

const DefiPnl = () => {
  const pnlError = useDefiStore(state => state.pnlError);
  const loadingPnl = useDefiStore(state => state.loadingPnl);
  const pnls = useDefiStore(state => state.pnl);
  const reset = useDefiStore(state => state.reset);
  const loadDefiPnl = useDefiStore(state => state.loadDefiPnl);

  const loadPnl = useCallback((address: string, tokens: Array<string>, chain: string) => {
    loadDefiPnl(address, tokens, chain);
  }, [loadDefiPnl]);

  const isNoData = useMemo(() => !pnls.data.pnl.filter(item => item.points.length).length, [pnls]);

  useEffect(() => {
    return reset;
  }, [reset]);

  return (
    <Flex vertical gap={'small'}>
      <Card>
        <CardHeader loadData={loadPnl}/>
      </Card>
      {isNoData && !loadingPnl && <EmptyMessage height={50} />}
      {loadingPnl && <LoadingCard structure={{ type: 'NODE', width: '100%', height: 354 }}/>}
      {pnls.data.pnl
        .filter(item => item.points.length)
        .map(item => (
          <Card>
            <PnlSection pnl={item} pnlError={pnlError} loadingPnl={loadingPnl}/>
          </Card>
        ))}
    </Flex>
  );
};

export default DefiPnl;
