import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TokenHistory } from 'model/Wallet';

import ScrollableTable from 'components/DataDisplay/ScrollableTable/ScrollableTable';
import TokenWithIcon from 'components/Icons/TokenWithIcon/TokenWithIcon';
import FormattedDate from 'components/Typography/FormattedDate/FormattedDate';
import TokenAmount from 'components/Typography/TokenAmount/TokenAmount';
import UsdValue from 'components/Typography/UsdValue/UsdValue';

interface CardHeaderProps {
  history: TokenHistory;
  loadingHistory: boolean;
  error?: string;
}

const HistoryTable = ({ history, error, loadingHistory }: CardHeaderProps) => {
  const { formatMessage } = useIntl();

  const columns = useMemo(() => {
    return [
      {
        title: formatMessage({ id: 'BALANCE_HISTORY_TABLE_TOKEN_COLUMN' }),
        dataIndex: 'token_symbol',
        key: 'token_symbol',
        width: 100,
        render: (token: string) => <TokenWithIcon token={token} iconSize={16} fontSize={12}/>,
        align: 'left' as const
      },
      {
        title: formatMessage({ id: 'BALANCE_HISTORY_TABLE_DATE_COLUMN' }),
        dataIndex: 'timestamp',
        key: 'timestamp',
        render: (value: number) => <FormattedDate timestamp={value} type={'seconds'}/>,
        width: 100,
        align: 'left' as const
      },
      {
        title: formatMessage({ id: 'BALANCE_HISTORY_TABLE_BLOCK_COLUMN' }),
        dataIndex: 'block',
        key: 'block',
        width: 100,
        align: 'right' as const
      },
      {
        title: formatMessage({ id: 'BALANCE_HISTORY_TABLE_PRICE_COLUMN' }),
        dataIndex: 'price',
        key: 'price',
        render: (value: number) => <UsdValue value={value} size={12}/>,
        width: 100,
        align: 'right' as const
      },
      {
        title: formatMessage({ id: 'BALANCE_HISTORY_TABLE_AMOUNT_COLUMN' }),
        dataIndex: 'amount',
        key: 'amount',
        render: (value: number) => <TokenAmount value={value} size={12}/>,
        width: 100,
        align: 'right' as const
      },
      {
        title: formatMessage({ id: 'BALANCE_HISTORY_TABLE_VALUE_COLUMN' }),
        dataIndex: 'value_usd',
        key: 'value_usd',
        render: (value: number) => <UsdValue value={value} size={12}/>,
        width: 100,
        align: 'right' as const
      }
    ];
  }, [formatMessage]);

  return (
    <ScrollableTable data={history.data}
                     columns={columns}
                     scrollSize={500}
                     pagination={false}
                     loading={loadingHistory}
                     error={error}
    />
  );
};

export default HistoryTable;
