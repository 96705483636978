import { ChainItem, ChainTokens, DefiItem, TokenChains, TokenItem } from 'model/Portfolio';
import { portfolioClient } from 'service/PortfolioService';
import { create } from 'zustand';

interface RootState {
  refreshTime: number;
  address: string;
  loadingPortfolio: boolean;
  defis: Array<DefiItem>;
  chains: Array<ChainItem>;
  chainTokens: Array<ChainTokens>;
  tokenChains: Array<TokenChains>;
  totalNetWorth: number;
  error?: string;
}

interface RootActions {
  setAddress: (address: string) => void;
  loadPortfolio: (address: string) => void;
  requestRefresh: () => void;
  reset: () => void;
}

const initialState: RootState = {
  loadingPortfolio: false,
  totalNetWorth: 0,
  refreshTime: 0,
  defis: [],
  chains: [],
  chainTokens: [],
  tokenChains: [],
  address: '',
  error: ''
};

export const useRootStore = create<RootState & RootActions>((set, get) => ({
  ...initialState,

  setAddress: (address: string) => {
    set({ address });
    if (!address) {
      set({
        loadingPortfolio: false,
        error: '',
        defis: [],
        chains: [],
        chainTokens: [],
        tokenChains: [],
        totalNetWorth: 0
      });
    }
  },

  loadPortfolio: async () => {
    const { address } = get();
    if (!address) {
      get().reset();
      return;
    }

    try {
      set({ loadingPortfolio: true, address });
      const portfolio = await portfolioClient.getPortfolio(address);
      const chainTokens = portfolio.data.tokens.reduce((acc: Record<string, ChainTokens>, token: TokenItem) => {
        if (!acc[token.chain]) {
          acc[token.chain] = {
            chain: token.chain,
            chainName: token.chain_name,
            tokens: [],
            valueUsd: 0
          };
        }

        acc[token.chain].valueUsd += token.usd_value;
        acc[token.chain].tokens.push(token);
        return acc;
      }, {});
      const tokenChains = portfolio.data.tokens.reduce((acc: Record<string, TokenChains>, token: TokenItem) => {
        if (!acc[token.name]) {
          acc[token.name] = {
            name: token.name,
            price: token.price,
            chains: [],
            valueUsd: 0
          };
        }

        acc[token.name].chains.push({
          chain: token.chain,
          chainName: token.chain_name,
          amount: token.amount,
          valueUsd: token.usd_value
        });
        return acc;
      }, {});
      set({
        loadingPortfolio: false,
        error: '',
        defis: portfolio.data.defis,
        chains: portfolio.data.chains,
        chainTokens: Object.values(chainTokens),
        tokenChains: Object.values(tokenChains),
        totalNetWorth: portfolio.data.total_value_usd
      });
    } catch (err) {
      set({
        ...initialState,
        address,
        error: err.message
      });
    }
  },

  requestRefresh: () => {
    set({ refreshTime: Date.now() });
  },

  reset: () => {
    set(initialState);
  }
}));
