import instance from 'config/AxiosConfig';
import { Pnl, TokenHistory, TokenHistoryRequest, TokenPnlRequest } from 'model/Wallet';

export class WalletClient {
  private static _instance = new WalletClient();

  static get instance(): WalletClient {
    return this._instance;
  }

  readonly host: string;

  constructor() {
    this.host = window.config.API_URL;
  }

  public async getTokenHistory({ address, token, chain }: TokenHistoryRequest): Promise<TokenHistory> {
    const url = `${this.host}/api/v1/wallets/${address}/tokens/${token}/chains/${chain}/history`;
    const result = await instance.get<TokenHistory>(url);
    return result.data;
  }

  public async getTokenPnl({ address, tokens, chain }: TokenPnlRequest): Promise<Pnl> {
    const url = `${this.host}/api/v1/wallets/${address}/chains/${chain}/pnl`;
    const result = await instance.get<Pnl>(url, { params: { tokens }, paramsSerializer: { indexes: null } });
    return result.data;
  }
}

export const walletClient = WalletClient.instance;
