import React from 'react';
import { Button } from 'antd';

import styles from './styles.module.css';

interface ButtonLinkProps {
  title: string;
  fontSize?: number;
  color?: string;
  onClick: () => void;
}

const ButtonLink = ({ title, onClick, color = 'rgba(0, 0, 0, 0.5)', fontSize = 12 }: ButtonLinkProps) => {
  return (
    <Button type="link" className={styles.linkButton} onClick={onClick} style={{ fontSize, color }}>{title}</Button>
  );
};

export default ButtonLink;
