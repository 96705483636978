import { Flex, Typography } from 'antd';

import ChainIcon from '../ChainIcon/ChainIcon';


interface ChainNameWithIconProps {
  chain: string;
  chainName?: string;
  iconSize?: number;
  fontSize?: number;
  className?: string;
}

const ChainNameWithIcon = ({ chain, chainName, className, iconSize = 24, fontSize = 12 }: ChainNameWithIconProps) => {
  return <Flex gap={'small'} align={'center'}>
    <ChainIcon chain={chain} chainName={chainName} size={iconSize}/>
    <Typography.Text className={className} style={{ fontSize }}>{chainName || chain}</Typography.Text>
  </Flex>;
};

export default ChainNameWithIcon;
