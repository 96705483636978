import { useMemo } from 'react';
import { Card, Flex, Typography } from 'antd';

import MicroLineChart from '../../../../../../components/Charts/MicroLineChart/MicroLineChart';
import CardTitle from '../../../../../../components/Typography/CardTitle/CardTitle';
import UsdValue from '../../../../../../components/Typography/UsdValue/UsdValue';

import styles from './styles.module.css';

interface StatsCardProps {
  title: string;
  help?: string;
  total: number;
  loading: boolean
  data: Array<{ [key: string]: number }>
  xField: string;
  yField: string
}

const StatsCard = ({ title, help, total, loading, data, xField, yField }: StatsCardProps) => {
  const value = useMemo(() => {
    if (!data.length) {
      return {
        value: 0,
        diff: null,
        color: 'red',
        smooth: true,
        data: []
      }
    }
    const first = data[0][yField]
    const last = data[data.length - 1][yField]
    const diff = Math.round(((last - first) / first) * 100 * 100) / 100;
    return {
      diff: diff >= 0 ? `+${diff}%` : `${diff}%`,
      color: diff >= 0 ? '#008846' : '#FF394A',
      data
  }
  }, [data, yField]);

  return (
    <Card
      className={styles.statsCard}
      bordered={false}
      loading={loading}
      title={<CardTitle title={title} help={help}/>}
    >
      <Flex justify={'end'}>
        <MicroLineChart data={value.data} xField={xField} yField={yField} color={value.color}/>
      </Flex>
      <Flex>
        <UsdValue value={total} size={18} strong/>
      </Flex>
      <Flex justify="space-between">
        <Typography.Text className={styles.period}>Since last day</Typography.Text>
        <Typography.Text className={styles.diff} style={{color: value.color}}>{value.diff}</Typography.Text>
      </Flex>
    </Card>
  );
};

export default StatsCard;
