import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Flex } from 'antd';
import { PnlItem } from 'model/Wallet';

import ColumnLineChart from 'components/Charts/ColumnLineChart/ColumnLineChart';
import UsdValue from 'components/Typography/UsdValue/UsdValue';

interface PnlSectionProps {
  pnl: PnlItem;
  loadingPnl: boolean;
  pnlError?: string;
}

const PnlSection = ({ pnl, pnlError }: PnlSectionProps) => {
  const { formatMessage } = useIntl();
  const title = useMemo(() => {
    if (!pnl.points.length) {
      return null;
    }

    const latest = pnl.points[pnl.points.length - 1];
    return (
      <Flex justify={'space-between'}>
        <div>{pnl.token}</div>
        <div>
          PnL: <UsdValue value={latest.value}/>
        </div>
      </Flex>
    );
  }, [pnl]);
  console.log(pnl);
  return (
    <ColumnLineChart loading={false}
                     error={pnlError}
                     data={pnl.points}
                     title={title}
                     xField={'timestamp'}
                     colors={['#F4A49E', '#43A6C6', '#B45AFA']}
                     charts={[
                       {
                         position: 'right',
                         type: 'interval',
                         yField: 'value',
                         titleColor: '#F4A49E',
                         yLabel: formatMessage({ id: 'COMMON_BALANCE_REWARD_USD' }),
                         colorField: 'type',
                         showY: true,
                         data: pnl.balanceRewardPoints,
                         yFormat: 'USD'
                       },
                       {
                         position: 'left',
                         type: 'line',
                         titleColor: '#B45AFA',
                         yField: 'value',
                         style: { lineWidth: 2 },
                         showY: true,
                         data: pnl.pnlPoints,
                         yLabel: formatMessage({ id: 'COMMON_PNL_VALUE_USD' }),
                         colorField: 'type',
                         yFormat: 'USD'
                       }
                     ]}/>
  );
};

export default PnlSection;
