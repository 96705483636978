import { useMemo } from 'react';
import { Tabs, TabsProps } from 'antd';

import styles from './styles.module.css';

interface HeaderTabsProps {
  items: TabsProps['items'];
  activeKey?: string;
  onChange?: (key: string) => void;
}

const HeaderTabs = ({ items, onChange, activeKey }: HeaderTabsProps) => {
  const preparedItems = useMemo(() => {
    return items?.map((item, index) => ({
      key: String(index),
      label: <span className={styles.label}>{item.label}</span>,
      children: item.children,
      disabled: item.disabled
    }))
  }, [items]);

  return (
    <Tabs defaultActiveKey={'0'} onChange={onChange} activeKey={activeKey} items={preparedItems} destroyInactiveTabPane />
  );
};

export default HeaderTabs;
