import { Flex, Typography } from 'antd';

import TokenIcon from 'components/Icons/TokenIcon/TokenIcon';
import TokenWithIcon from 'components/Icons/TokenWithIcon/TokenWithIcon';

import styles from './styles.module.css';

interface PoolNameProps {
  pools: string[];
}

const PoolName = ({ pools }: PoolNameProps) => {
  if (pools && pools.length === 1) {
    return <TokenWithIcon token={pools[0]} iconSize={16} fontSize={12}/>;
  }

  return <Flex vertical>
    <div className={styles.poolIcons}>
      {pools.map(pool => (
        <span>
          <TokenIcon token={pool} size={16}></TokenIcon>
        </span>
      ))}
    </div>
    <Typography.Text style={{ fontSize: 12 }}>
      {pools.reduce((acc, name) => (acc ? `${acc}+${name}` : name), '')}
    </Typography.Text>
  </Flex>;
};

export default PoolName;
