import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, Flex } from 'antd';
import { ChainItem } from 'model/Portfolio';
import { useRootStore } from 'store/portfolioStore';

import ButtonLink from 'components/Buttons/ButtonLink/ButtonLink';
import EmptyMessage from 'components/States/EmptyMessage/EmptyMessage';

import ErrorMessage from '../../../../components/States/ErrorMessage/ErrorMessage';
import LoadingCard from '../../../../components/States/LoadingCard/LoadingCard';

import ChainCard from './components/ChainCard/ChainCard';

import styles from './styles.module.css';

const VISIBLE_CHAINS_COUNT = 3;


const TokenAllocation = () => {
  const { formatMessage } = useIntl();
  const [hideChains, setHideChains] = useState(true);
  const loadingPortfolio = useRootStore(state => state.loadingPortfolio);
  const error = useRootStore(state => state.error);
  const chains = useRootStore(state => state.chains);
  const totalNetWorth = useRootStore(state => state.totalNetWorth);

  const title = useMemo(() => {
    return hideChains
      ? formatMessage({ id: 'DASHBOARD_TOKEN_ALLOCATION_SHOW_CHAINS' }, { count: chains.length - VISIBLE_CHAINS_COUNT})
      : formatMessage({ id: 'DASHBOARD_TOKEN_ALLOCATION_HIDE_CHAINS' });
  }, [hideChains, chains, formatMessage]);

  const filteredChains = useMemo(() => {
    if (hideChains) {
      return chains.slice(0, VISIBLE_CHAINS_COUNT);
    }

    return chains;
  }, [chains, hideChains]);

  const toggleHide = useCallback(() => {
    setHideChains(prev => !prev);
  }, [setHideChains]);

  if (loadingPortfolio) {
    return <LoadingCard structure={{ type: 'NODE', width: '100%', height: 160 }}/>;
  }

  return <Card
    className={styles.tokenAllocationCard}
    bordered={false}
    loading={loadingPortfolio}
    title={formatMessage({ id: 'DASHBOARD_TOKEN_ALLOCATION' })}
  >
    {!chains.length && !error && <EmptyMessage height={77}/>}
    {!chains.length && error && <ErrorMessage height={77}/>}
    <Flex gap={'small'} wrap>
      {filteredChains.map((chain: ChainItem) => (
        <ChainCard key={chain.id}
                   chainId={chain.id}
                   chainName={chain.name}
                   value={chain.value_usd} totalValue={totalNetWorth}/>
      ))}
      {chains.length > VISIBLE_CHAINS_COUNT && (
        <Flex justify="center" align="center">
          <ButtonLink title={title} onClick={toggleHide}/>
        </Flex>
      )}
    </Flex>
  </Card>;
};

export default TokenAllocation;
