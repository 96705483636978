import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Flex, Typography } from 'antd';
import { ReactComponent as WarningIcon } from 'resource/icons/warning.svg';
import { useRootStore } from 'store/portfolioStore';

import styles from './styles.module.css';

interface EmptyMessageProps {
  height?: string | number;
}

const ErrorMessage = ({ height = '100%' }: EmptyMessageProps) => {
  const { formatMessage } = useIntl();
  const requestRefresh = useRootStore(state => state.requestRefresh);

  return (
    <Flex className={styles.error} align="center" justify="center" style={{ height }} gap="small">
      <WarningIcon/>
      <Typography.Text className={styles.errorText}>
        {formatMessage({ id: 'COMMON_ERROR' })}
      </Typography.Text>
      <Button type="link"
              className={styles.refreshButton}
              onClick={requestRefresh}>
        {formatMessage({ id: 'COMMON_REFRESH' })}
      </Button>
    </Flex>
  );
};

export default ErrorMessage;
