import { ThemeConfig } from 'antd';

export const appTheme: ThemeConfig = {
  token: {
    fontSize: 14,
    fontWeightStrong: 500,
    borderRadius: 4,
    colorPrimary: '#7663E4',
    fontFamily: '\'OpenSauceSans\', -apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',' +
      ' \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',' +
      ' sans-serif'
  },
  components: {
    Menu: {
      itemBorderRadius: 8,
      itemColor: 'rgba(108, 111, 121, 1)',
      itemSelectedColor: 'rgba(118, 99, 228, 1)',
      itemSelectedBg: 'rgba(118, 99, 228, 0.1)'
    },
    Table: {
      headerBg: 'white'
    },
    Select: {
      optionFontSize: 12
    },
    Card: {
      headerFontSize: 14
    }
  },
};
