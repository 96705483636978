import { ReactNode, useEffect } from 'react';
import { Flex, Layout, Spin } from 'antd';
import AuthorizedUser from 'layouts/LambdaLayout/components/AuthorizedUser/AuthorizedUser';
import NavBar from 'layouts/LambdaLayout/components/NavBar/NavBar';
import SettingsMenu from 'layouts/LambdaLayout/components/SettingsMenu/SettingsMenu';
import { ReactComponent as LogoIcon } from 'resource/images/logo.svg';
import { useDictionaryStore } from 'store/dictionaryStore';
import { useRootStore } from 'store/portfolioStore';
import { useSessionStore } from 'store/sessionStore';

import AddressHeader from './components/AddressHeader/AddressHeader';

import styles from 'layouts/LambdaLayout/styles.module.css';


interface LambdaLayoutProps {
  children: ReactNode;
}

const LambdaLayout = ({ children }: LambdaLayoutProps) => {
  const loadingSession = useSessionStore(state => state.loadingSession);
  const getSession = useSessionStore(state => state.getSession);
  const user = useSessionStore(state => state.user);
  const getDictionaries = useDictionaryStore(state => state.getDictionaries);

  const address = useRootStore(state => state.address);
  const setAddress = useRootStore(state => state.setAddress);
  const loadingPortfolio = useRootStore(state => state.loadingPortfolio);
  const loadPortfolio = useRootStore(state => state.loadPortfolio);

  const refreshTime = useRootStore(state => state.refreshTime);
  const requestRefresh = useRootStore(state => state.requestRefresh);

  useEffect(() => {
    getSession();
  }, [getSession]);

  useEffect(() => {
    user && getDictionaries()
  }, [user, getDictionaries]);

  useEffect(() => {
    address && loadPortfolio(address);
  }, [address, loadPortfolio, refreshTime]);

  return (
    <Flex wrap>
      <Layout className={styles.layoutSize}>
        <Layout.Sider width="216" className={styles.layoutSider}>
          <Flex vertical gap="large" justify="space-between" style={{ height: '100%' }}>
            <Flex vertical gap="large">
              <LogoIcon className={styles.logo}/>
              <NavBar/>
            </Flex>
            <Flex vertical gap="large">
              <SettingsMenu refresh={requestRefresh}/>
              <AuthorizedUser user={user}/>
            </Flex>
          </Flex>
        </Layout.Sider>
        <Layout>
          <Layout.Content className={styles.layoutContent}>
            <Spin spinning={loadingSession}>
              <Flex vertical gap="large">
                <AddressHeader address={address} setAddress={setAddress} loadingPortfolio={loadingPortfolio}/>
                <div className={styles.layoutPage}>
                  {children}
                </div>
              </Flex>
            </Spin>
          </Layout.Content>
        </Layout>
      </Layout>
    </Flex>
  );
};

export default LambdaLayout;
