import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Flex } from 'antd';
import { ChainTokenItem, SupportedChainItem } from 'model/Dictionary';
import { ReactComponent as DownloadIcon } from 'resource/icons/download.svg';
import { useDictionaryStore } from 'store/dictionaryStore';
import { useRootStore } from 'store/portfolioStore';

import ButtonOutlined from 'components/Buttons/ButtonOutlined/ButtonOutlined';
import ChainFilter from 'components/Filters/ChainFilter/ChainFilter';
import TokenFilter from 'components/Filters/TokenFilter/TokenFilter';

interface CardHeaderProps {
  mode?: 'table' | 'chart';
  toggleMode?: () => void;
  loadData: (address: string, token: string, chain: string) => void;
}

const CardHeader = ({ toggleMode, mode, loadData }: CardHeaderProps) => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const [selectedToken, setSelectedToken] = useState<ChainTokenItem>();
  const [selectedChain, setSelectedChain] = useState<SupportedChainItem>();
  const chainsWithHistory = useDictionaryStore(state => state.chainsWithHistory);
  const address = useRootStore(state => state.address);
  const refreshTime = useRootStore(state => state.refreshTime);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenId = queryParams.get('token');
    const chainId = queryParams.get('chain');
    if (tokenId && chainId) {
      const chain = chainsWithHistory.chains.find(chain => chain.id === chainId);
      setSelectedChain(chain);
      const token = chain?.tokens.find(token => token.symbol === tokenId)
      setSelectedToken(token);
    }
  }, [chainsWithHistory, location.search]);

  useEffect(() => {
    address && selectedToken && selectedChain && loadData(address, selectedToken.symbol, selectedChain.id);
  }, [loadData, address, selectedChain, selectedToken, refreshTime]);

  const onChainChange = useCallback((item?: SupportedChainItem) => {
    setSelectedChain(item);
    setSelectedToken(undefined);
  }, [setSelectedChain, setSelectedToken]);

  return (
    <Flex justify={'space-between'} align={'center'}>
      <Flex gap={'middle'} wrap>
        <ChainFilter chains={chainsWithHistory.chains}
                     selectedChain={selectedChain}
                     onChange={onChainChange}/>
        <TokenFilter tokens={selectedChain?.tokens}
                     selectedToken={selectedToken}
                     onChange={setSelectedToken}
                     disabled={!selectedChain}/>
      </Flex>
      <Flex gap={'small'}>
        {mode && <ButtonOutlined text={mode === 'table' ? 'Chart' : 'Table'} onClick={toggleMode}/>}
        <ButtonOutlined
          text={formatMessage({ id: 'COMMON_DOWNLOAD_CSV' })}
          icon={<DownloadIcon/>}
        />
      </Flex>
    </Flex>
  );
};

export default CardHeader;
