import { Flex } from 'antd';

import AddressInfo from './components/AddressInfo/AddressInfo';
import SearchAddress from './components/SearchAddress/SearchAddress';

import styles from './styles.module.css';

interface AddressHeaderProps {
  address: string;
  loadingPortfolio: boolean;
  setAddress: (address: string) => void;
}

const AddressHeader = ({ address, loadingPortfolio, setAddress }: AddressHeaderProps) => {
  return (
    <Flex className={styles.header} justify={'space-between'}>
      <AddressInfo address={address} loadingPortfolio={loadingPortfolio} />
      <SearchAddress address={address} setAddress={setAddress}/>
    </Flex>
  );
};

export default AddressHeader;
