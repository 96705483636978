import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, MenuProps } from 'antd';
import { ReactComponent as KeyIcon } from 'resource/icons/key.svg';
import { ReactComponent as OverviewIcon } from 'resource/icons/overview.svg';
import { ReactComponent as ScalesIcon } from 'resource/icons/scales.svg';
import { ReactComponent as WalletIcon } from 'resource/icons/wallet.svg';

import styles from 'layouts/LambdaLayout/components/NavBar/styles.module.css';

const NavBar = () => {
  const { formatMessage } = useIntl();
  const [selectedKey, setSelectedKey] = useState('');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setSelectedKey(pathname);
  }, [pathname]);

  const handleNavigate: MenuProps['onClick'] = useCallback((menuItem: { key: string }) => {
    if (menuItem.key === '/public-api') {
      window.open('https://p2p-lambda.readme.io/docs/quick-start', '_blank', 'noopener,noreferrer');
      return;
    }

    navigate(menuItem.key);
  }, [navigate]);

  return (
    <Menu
      className={styles.navBar}
      mode="inline"
      selectedKeys={[selectedKey]}
      onClick={handleNavigate}
      items={[
        {
          key: '/',
          icon: <OverviewIcon/>,
          label: formatMessage({ id: 'NAV_BAR_OVERVIEW' })
        },
        {
          key: '/wallets',
          icon: <WalletIcon/>,
          label: formatMessage({ id: 'NAV_BAR_WALLET_BALANCE' })
        },
        {
          key: '/defi-positions',
          icon: <ScalesIcon/>,
          label: formatMessage({ id: 'NAV_BAR_DEFI_POSITIONS' })
        },
        {
          key: '/public-api',
          icon: <KeyIcon/>,
          label: formatMessage({ id: 'NAV_BAR_API' }),
        }
      ]}
    />
  );
};

export default NavBar;
