import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Line } from '@ant-design/charts';
import { Card } from 'antd';
import { ChartInterval } from 'model/Chart';
import { toShortPrice } from 'util/number';

import Select from 'components/Inputs/Select/Select';
import EmptyMessage from 'components/States/EmptyMessage/EmptyMessage';
import ErrorMessage from 'components/States/ErrorMessage/ErrorMessage';
import LoadingCard from 'components/States/LoadingCard/LoadingCard';
import CardTitle from 'components/Typography/CardTitle/CardTitle';

interface LineChartProps {
  loading: boolean;
  title?: ReactNode;
  help?: string;
  error?: string;
  xField: string;
  transform?: any,
  yField: string;
  tooltipLabel?: string;
  color: string | string[];
  data: Array<Record<string, any>>;
  onIntervalChange?: (interval: string) => void;
}

const LineChart = ({
                     title,
                     help,
                     data,
                     xField,
                     yField,
                     tooltipLabel,
                     loading,
                     error,
                     color,
                     transform,
                     onIntervalChange
                   }: LineChartProps) => {
  const { formatMessage } = useIntl();
  const [interval, setInterval] = useState<string>(ChartInterval.ONE_DAY);

  const toDate = useCallback((data: { [key: string]: number }) => {
    return new Date(data[xField] * 1000);
  }, [xField]);

  const toValue = useCallback((data: { [key: string]: number }) => {
    return Number(data[yField].toFixed(2));
  }, [yField]);

  const options = useMemo(() => ([
    { value: ChartInterval.ONE_HOUR, label: formatMessage({ id: 'DASHBOARD_NET_WORTH_1_HOUR' }) },
    { value: ChartInterval.ONE_DAY, label: formatMessage({ id: 'DASHBOARD_NET_WORTH_1_DAY' }) },
    { value: ChartInterval.ONE_WEEK, label: formatMessage({ id: 'DASHBOARD_NET_WORTH_1_WEEK' }) },
    { value: ChartInterval.ONE_MONTH, label: formatMessage({ id: 'DASHBOARD_NET_WORTH_MONTH' }) },
    { value: ChartInterval.ONE_YEAR, label: formatMessage({ id: 'DASHBOARD_NET_WORTH_YEAR' }) },
    { value: ChartInterval.ALL_TIME, label: formatMessage({ id: 'DASHBOARD_NET_WORTH_ALL_TIME' }) }
  ]), [formatMessage]);

  const handleIntervalChange = useCallback((newInterval: string) => {
    onIntervalChange && onIntervalChange(newInterval);
    setInterval(newInterval);
  }, [setInterval, onIntervalChange]);

  if (loading) {
    return <LoadingCard structure={{ type: 'NODE', width: '100%', height: 354 }}/>;
  }

  return (
    <Card
      bordered={false}
      loading={loading}
      title={title && typeof title === 'string' ? <CardTitle title={title} help={help}/> : title}
      extra={onIntervalChange && <Select options={options} defaultValue={interval} onChange={handleIntervalChange}/>}
    >
      <div style={{ height: 262 }}>
        {!data.length && !error && <EmptyMessage/>}
        {!data.length && error && <ErrorMessage/>}
        <Line data={{ value: data, transform }}
              xField={toDate}
              yField={toValue}
              colorField="type"
              seriesField="type"
              tooltip={tooltipLabel ? {
                items: [{
                  name: tooltipLabel, channel: 'y'
                }],
              } : undefined}
              axis={{ y: { labelFormatter: toShortPrice, tickCount: 4, grid: true, gridLineDash: null }, x: { grid: false } }}
              style={{ lineWidth: 2 }}
              scale={{ color: { range: Array.isArray(color) ? color : [color] } }}
              legend={false}/>
      </div>
    </Card>
  );
};

export default LineChart;
