import { useMemo } from 'react';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface DateProps {
  timestamp?: number;
  type: 'seconds' | 'milliseconds';
  size?: number;
  strong?: boolean;
}

const FormattedDate = ({ timestamp, strong, type, size = 12 }: DateProps) => {
  return useMemo(() => {
    if (!timestamp) {
      return null;
    }

    const date = dayjs.utc(type === 'seconds' ? timestamp * 1000 : timestamp).format('YYYY-MM-DD HH:mm:ss');
    return <Typography.Text strong={strong} style={{ fontSize: size }}>
      {date} (UTC)
    </Typography.Text>;
  }, [timestamp, size, strong, type]);
};

export default FormattedDate;
