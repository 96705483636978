import { Flex } from 'antd';

import styles from './styles.module.css';

interface PositionTypeHeaderProps {
  type: string;
}

const PositionTypeHeader = ({ type }: PositionTypeHeaderProps) => {

  return (
    <Flex className={styles.title}>
      <div>{type}</div>
    </Flex>
  );
};

export default PositionTypeHeader;
