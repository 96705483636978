import { useCallback, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import { ReactComponent as CopyIcon } from 'resource/icons/copy.svg';

import styles from './styles.module.css';

interface CopyableTextProps {
  text?: string;
  size: number;
  className?: string;
}

const CopyableText = ({ className, text, size = 14 }: CopyableTextProps) => {
  const [copied, setCopied] = useState(false);
  const copyText = useCallback((event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    text && navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(() => false), 1000);
  }, [text, setCopied]);

  if (!text) {
    return null;
  }

  return <Flex align={'center'} gap={'small'} style={{ height: size }}>
    <Typography.Text className={className}
                     ellipsis
                     style={{ fontSize: size }}>
      {text}
    </Typography.Text>
    <div role="button" onClick={copyText} className={styles.copyButton}
         style={{ height: size, lineHeight: `${size}px` }}>
      {copied
        ? <CheckOutlined width={size} height={size} style={{ color: '#008846' }} />
        : <CopyIcon className={styles.copyIcon} width={size} height={size}/>}
    </div>
  </Flex>;
};

export default CopyableText;
