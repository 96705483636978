import instance from 'config/AxiosConfig';
import { SessionUser } from 'model/User';

export class SessionClient {
  private static _instance = new SessionClient();

  static get instance(): SessionClient {
    return this._instance;
  }

  readonly host: string;

  constructor() {
    this.host = window.config.API_URL;
  }

  public async getSession(): Promise<SessionUser> {
    const url = `${this.host}/api/v1/sessions`;
    const result = await instance.get<SessionUser>(url);
    return result.data;
  }
}

export const sessionClient = SessionClient.instance;
