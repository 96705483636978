import { Typography } from 'antd';

interface RoundedValueProps {
  value: number;
  size?: number;
  strong?: boolean;
  className?: string;
}

const RoundedValue = ({ className, value, size = 14, strong = false }: RoundedValueProps) => {
  return <Typography.Text strong={strong} className={className} style={{ fontSize: size }}>
    {String(Math.round(value * 100) / 100)}
  </Typography.Text>;
};

export default RoundedValue;
