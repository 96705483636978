import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Flex, Row, Typography } from 'antd';
import { SessionUser } from 'model/User';
import { ReactComponent as ArrowDownIcon } from 'resource/icons/arrow/arrow-down.svg';

import CopyableText from 'components/Typography/CopyableText/CopyableText';

import styles from './styles.module.css';

interface AuthorizedUserProps {
  user?: SessionUser
}

const AuthorizedUser = ({ user }: AuthorizedUserProps) => {
  return (
    <Row className={styles.authorizedUser}>
      <Col span={6}>
        <Avatar shape="square" size={38} icon={<UserOutlined/>}/>
      </Col>
      <Col span={16}>
        <Flex vertical gap={'small'}>
          <Typography.Text className={styles.userName} strong>{user?.full_name}</Typography.Text>
          <CopyableText className={styles.address} text={user?.address} size={10}/>
        </Flex>
      </Col>
      <Col span={2}>
        <Flex align="center" justify="center" className={styles.arrowContainer}>
          <ArrowDownIcon width={8} height={8}/>
        </Flex>
      </Col>
    </Row>
  );
};

export default AuthorizedUser;
